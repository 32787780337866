import React, { Fragment, useState, useEffect, useRef } from "react";
import CustomLoader from "../../customLoader/CustomLoader";
import GetAdminService from "../../../services/admin/GetAdminService";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import { Table } from "reactstrap";
import moment from "moment";

const SftpDetails = () => {

    const [districtName, setDistrictName] = useState();
  const [showDistrictNameContainer, setShowDistrictNameContainer] = useState(
    false
  );
  const [districtNameList, setDistrictNameList] = useState();
  const [districtID, setDistrictID] = useState();
  const [pageData, setPageData] = useState();
  const districtNameRef = useRef();
  const timeoutRef = useRef();
  const [districtNameLoading, setDistrictNameLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [clickedSchool, setClickedSchool] = useState();

  const getDistrictsList = (value) => {
    setDistrictNameLoading(true);
    GetAdminService.getDistrictsList(value)
      .then((data) => {
        console.log("data from the service", data);
        setDistrictNameList(data?.data?.objects);
        setDistrictNameLoading(false);
        setShowDistrictNameContainer(true);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setDistrictNameLoading(false);
      });
  };

  const handleDistrictName = () => {
    clearTimeout(timeoutRef.current);
    setDistrictName(districtNameRef.current.value);
    if (!districtNameRef.current.value) {
      setShowDistrictNameContainer(false);
      setDistrictNameList([]);
      setPageData([]);
    } else {
      timeoutRef.current = setTimeout(() => {
        getDistrictsList(districtNameRef.current.value);
      }, 1000);
    }
  };

  const handleSelectedDistrictName = (item) => {
    setDistrictID(item?.id);
    setDistrictNameList([item]);
    setShowDistrictNameContainer(false);
    setDistrictName(item.name);
    getDistrictPage(item?.id);
  };

  const getDistrictPage = (id) => {
    let obj = {
      district: id,
    };
    setLoading(true);
    GetAdminService.districtSftpPage(obj)
      .then((data) => {
        console.log("data from the service", data);
        setPageData(data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="csv-import-container">
            <div className="flex canned-message-heading">
            <p style={{ fontSize: "25px", fontWeight: "400" }}>SFTP Information</p>
            </div>
            <div className="school-name-container">
              <p>Select District</p>
              <div>
                <input
                  ref={districtNameRef}
                  className="search-admin-school-input"
                  type="text"
                  placeholder="District Name"
                  value={districtName}
                  onChange={handleDistrictName}
                  autoComplete="off"
                />
                {showDistrictNameContainer && (
                  <div>
                    <ul
                      className="maintain-group-student-dropdown-menu"
                      style={{
                        width: "700px",
                        maxHeight: "600px",
                        overflowY: "scroll",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      {districtNameLoading && <CustomLoader />}
                      {districtNameList?.length > 0 &&
                        districtNameList.map((item, index) => (
                          <li
                            onClick={() => handleSelectedDistrictName(item)}
                            key={index}
                            className="flex admin-school-list cursor"
                          >
                            <span>{item.name}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {loading ? (
              <CustomLoader />
            ) : (
              pageData && (
                <Table
                  className="text-center"
                  bordered
                  style={{
                    width: "90%",
                    background: "white",
                    margin: "0 20px",
                  }}
                >
                    {pageData?.objects?.length > 0 &&
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}>School Name</th>
                      <th style={{ textAlign: "center" }}>Last SFTP Date</th>
                      <th style={{ textAlign: "center" }}>SFTP Status</th>
                    </tr>
                  </thead>}
                  <tbody>
                    {pageData?.objects?.map((item, index) => {
                      return (
                        <tr>
                          {/* <th scope="row">1</th> */}
                          <td style={{ maxWidth: "400px" }}>{item?.school_name}</td>
                          <td>
                          {moment(item.date_created).format("ddd MMM Do YYYY, hh:mm:ss a")}
                          </td>
                          <td>
                            {item?.status}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SftpDetails;
