import { request } from "./Request";
import { routes } from "../constants/constant.route";
import { urlConstants } from "../utils/urlConstants";
// import { routes } from '../constants/constantAPIRoute'

const { API_URL } = urlConstants;
const { STUDENT_GOAL, GOAL, STUDENT_HISTORY, STUDENT_PDF_REFLECTION, STUDENT_SAVE_REFLECTION} = routes.STUDENT;

export default class StudentGoalService {
  //    static requirement(body){
  //        return request('POST', `${API_URL}${routes.studentgoal.requirement}` , null , body , null )
  //    }
  //    static getGoal(schoolValue, studentValue){
  //        return request('GET',`${API_URL}${routes.studentgoal.studentgoal}?school=${schoolValue}&student=${studentValue}`);
  //    }
  static goal(goalId) {
    return request(
      "GET",
      `${API_URL}${GOAL}/${goalId}`,
      null,
      null,
      null,
      null
    );
  }

  static getGoal() {
    return request(
      "GET",
      `${API_URL}${STUDENT_GOAL}`
    );
  }

  static getPdfReflection(obj) {
    return request(
      "GET",
      `${API_URL}${STUDENT_PDF_REFLECTION}`
    );
  }

  static getStudentHistoryData = (body) => {
    return request('POST', `${API_URL}${STUDENT_HISTORY}`, null, body, null, null, null)
  }

  static saveReflection = (body) => {
    return request('PUT', `${API_URL}${STUDENT_SAVE_REFLECTION}`, null, body, null, null, null)
  }
}