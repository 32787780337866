import React from "react";
import { Modal } from "reactstrap";

function Privacy({ isOpen, setIsOpen }) {
  const toggle = () => {
    setIsOpen(false);
  };
  return (
    <Modal isOpen={isOpen} centered={true} toggle={toggle}>
      <div className="modal-header">
        <h4 className="modal-title w3-text-blue">
          <center>Siembra Mobile, Inc. Privacy Policy</center>
        </h4>
        <button
          type="button"
          className="close"
          onClick={toggle}
          aria-hidden="true"
        >
          ×
        </button>
      </div>
      <div
        style={{ padding: "25px", textAlign: "justify" }}
        className="modal-body"
      >
        <h3>
          <span style={{ fontSize: "medium" }}>
            <strong>
              Privacy Statement for Community College Students
            </strong>
          </span>
        </h3>
        <p>
          <span style={{ fontSize: "small" }}>
            Siembra Mobile Inc. recognizes the importance of safeguarding the
            privacy of individual personal information. We collect and retain
            personal information from individuals using our services, and
            personal information (i.e., student data) from other sources that is
            provided to us as authorized by applicable law.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            This Statement describes our privacy practices as required by the
            Information Practices Act (Civil Code Section 1798 et seq.),
            Government Code Sections 11015.5 and 11019.9, and other applicable
            laws. Consistent with the Information Practices Act, Siembra Mobile
            Inc. broadly defines “personal information” as any information that
            identifies, relates to, describes, or is capable of being associated
            with a particular individual.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            We follow the principles listed below when we collect, retain, and
            disclose individual personal information, including electronically
            collected personal information.
          </span>
        </p>
        <ul>
          <li style={{ fontSize: "small" }}>
            Limit data collection and use of personal information to what is
            relevant and necessary to accomplish a lawful purpose of our
            services.
          </li>
          <li style={{ fontSize: "small" }}>
            At the time of collection, we disclose to individuals who provide
            personal information about the specific uses for which we will use
            that information. We provide a privacy notice on or with the
            application used to collect personal information.
          </li>
          <li style={{ fontSize: "small" }}>
            Personal information will not be disclosed, made available, or
            otherwise used for purposes other than those specified in this
            policy, unless with the consent of the subject of the information,
            or as authorized by law.
          </li>
          <li style={{ fontSize: "small" }}>
            Allow individuals to review personal information and correct its
            accuracy or completeness.
          </li>
          <li style={{ fontSize: "small" }}>
            Develop polices to protect and safeguard personal information
            collected and retained by us or our agents, including policies that
            protect against the loss or destruction and unauthorized access or
            disclosure of personal data. We use encryption software to protect
            the security of an individual’s personal information during
            transmission of such information through Siembra Mobile’s
            application.
          </li>
          <li style={{ fontSize: "small" }}>
            Maintain clear responsibilities for the protection of personal
            information, including those of a Privacy Coordinator responsible
            for overseeing Siembra Mobile’s privacy program.
          </li>
          <li style={{ fontSize: "small" }}>
            Collect the following electronic personal information: name, contact
            information, degree program, cumulative GPA.
          </li>
          <li style={{ fontSize: "small" }}>
						We respect user privacy and do not request permission to track user activity across apps and websites, as we do not engage in such practices.
					</li>
          <li style={{ fontSize: "small" }}>
            We do not distribute or sell any electronically collected personal
            information about individuals to any third party outside non-profit
            postsecondary institutions or employer.
          </li>
          <li style={{ fontSize: "small" }}>
            Electronically collected personal information is exempt from
            requests made under the Public Records Act.
          </li>
          <li style={{ fontSize: "small" }}>
            Inform individuals of their right to have any electronically
            collected personal information deleted, without reuse or
            distribution. Please see “Contact Us” below to request deletion.
          </li>
        </ul>
        <p>
          <span style={{ fontSize: "small" }}>
            <strong>CONTACT INFORMATION</strong>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            To request access to your records, report any inaccuracies, provide
            comments, request deletions, or file complaints related to privacy
            or personal information access requests.
          </span>
        </p>
        <ul>
          <li style={{ fontSize: "small" }}>Siembra Mobile, Inc. </li>
          <li style={{ fontSize: "small" }}>
            Email: support@siembramobile.com{" "}
          </li>
        </ul>
        <br />
      </div>
    </Modal>
  );
}
export default Privacy;
