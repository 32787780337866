import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  faTimes,
  faAsterisk,
  faSave,
  faTrash,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GetAdminService from "../../../services/admin/GetAdminService";
import swal from "sweetalert";
import _ from "lodash";
import { singleState } from "../../../constants/data";
import "./School.css";
import { Modal, ModalBody } from "reactstrap";
import CustomLoader from "../../customLoader/CustomLoader";

const DeleteModal = ({ isOpen, setIsOpen, fun1 }) => {
  return (
    <Fragment>
      <Modal isOpen={isOpen} centered={true}>
        <ModalBody>
          <div style={{ textAlign: "center" }}>
            <p>Are you sure you want to delete?</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                onClick={fun1}
                style={{
                  backgroundColor: "#009900",
                  color: "white",
                  padding: "5px",
                  border: "none",
                  outline: "none",
                  marginRight: "20px",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon
                  style={{ margin: "0 5px" }}
                  color="white"
                  icon={faCheck}
                />
                Ok
              </button>
              <button
                onClick={() => setIsOpen(false)}
                style={{
                  backgroundColor: "#efefef",
                  color: "black",
                  padding: "5px",
                  border: "none",
                  outline: "none",
                  marginLeft: "20px",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon style={{ margin: "0 5px" }} icon={faTimes} />
                Cancel
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

const NewSchool = ({ setOpenNewSchool, modify, schoolId}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [schoolName, setSchoolName] = useState();
  const [schoolCode, setSchoolCode] = useState();
  const [addressName, setAddressName] = useState();
  const districtNameRef = useRef();
  const [cityName, setCityName] = useState();
  const timeoutRef = useRef();
  const [pageData, setPageData] = useState();
  const [districtID, setDistrictID] = useState();
  const [districtNameLoading, setDistrictNameLoading] = useState(false);
  const [districtNameList, setDistrictNameList] = useState();
  const [zip, setZip] = useState();
  const [isHidden, setIsHidden] = useState(true);
  const [isMiddle, setIsMiddle] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isProgress, setIsProgress] = useState(false);
  const [districtName, setDistrictName] = useState();
  const stateFromData = JSON.parse(JSON.stringify(singleState));
  const [selectedState, setSelectedState] = useState();
  const [showDistrictNameContainer, setShowDistrictNameContainer] = useState(
    false
  );

  const deleteSchool = () => {
    setIsDeleted(true);
    //  let obj = {
    //   address: modify?.address?.address,
    //   ceeb_code: modify.ceeb_code,
    //   city: modify?.address?.city,
    //   codename: modify?.codename,
    //   // email_count: modify.email_count,
    //   id: schoolId,
    //   isHidden: modify.isHidden,
    //   isSigned: modify.isSigned,
    //   is_deleted: true,
    //   is_middle_school: modify.is_middle_school,
    //   // mobile_count: modify.mobile_count,
    //   name: modify.name,
    //   // resource_uri: modify.resource_uri,
    //   sso_enable: modify.sso_enable,
    //   state: modify?.address?.state,
    //   student_progress_enable: modify.student_progress_enable,
    //   upload_csv: modify.upload_csv,
    //   zip_code: modify?.address?.zip_code,
    // };

      //   name: modify?.name,
      //   codename: modify?.codename,
      //   address: {
      //     address:modify?.address,
      //     city: modify?.city,
      //     state: modify?.state,
      //     zip_code: modify?.zip,
      //   },
      //   is_middle_school: modify?.is_middle_school,
      //   student_progress_enable: modify?.student_progress_enable,
      //   isHidden: modify?.isHidden,
      // };
    GetAdminService.getDeleteSchool(schoolId)
      .then((data) => {
        console.log("data from the service", data);
        swal({
          title: "Successfully",
          text: "School deleted successfully",
          icon: "success",
          dangerMode: true,
          buttons: {
            ok: true,
          },
          closeOnClickOutside: true,
        });
      })
      .catch((error) => {
        console.log("error from the service", error);
        swal({
          title: "Oops!!",
          text: error.response.data.message || "Something went wrong",
          icon: "warning",
          dangerMode: true,
          buttons: {
            cancel: true,
          },
          closeOnClickOutside: true,
        });        
      });
      setOpenDeleteModal(false);
      setOpenNewSchool(false);
  };

  const getDistrictsList = (value) => {
    setDistrictNameLoading(true);
    GetAdminService.getDistrictsList(value)
      .then((data) => {
        console.log("data from the service", data);
        setDistrictNameList(data?.data?.objects);
        setDistrictNameLoading(false);
        setShowDistrictNameContainer(true);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setDistrictNameLoading(false);
      });
  };

  const handleDistrictName = () => {
    clearTimeout(timeoutRef.current);
    setDistrictName(districtNameRef.current.value);
    if (!districtNameRef.current.value) {
      setShowDistrictNameContainer(false);
      setDistrictNameList([]);
      setPageData([]);
    } else {
      timeoutRef.current = setTimeout(() => {
        getDistrictsList(districtNameRef.current.value);
      }, 1000);
    }
  };

  const handleSelectedDistrictName = (item) => {
    setDistrictID(item?.id);
    setDistrictNameList([item]);
    setShowDistrictNameContainer(false);
    setDistrictName(item.name);
    // getDistrictPage(item?.id);
  };

  const handleSchoolName = (event) => {
    const newValue = event.target.value;
    setSchoolName(newValue);
    if(!modify){
    setSchoolCode(newValue.toLowerCase().replace(/\s+/g, "-"))
    }
    else{
      setSchoolCode(modify?.codename)
    }
  };

  const handleCheckboxHidden = () => {
    setIsHidden((prev) => !prev);
  };

  const handleCheckboxMiddle = () => {
    setIsMiddle((prev) => !prev);
  };

  const handleCheckboxProgress = () => {
    setIsProgress((prev) => !prev);
  };

  const handleSelectState = (event) => {
    setSelectedState(event.target.value);
  };

  const handleAddressName = (event) => {
    setAddressName(event.target.value);
  };

  const handleCityName = (event) => {
    setCityName(event.target.value);
  };

  const handleZip = (event) => {
    if (event.target.value.length <= 6) {
      setZip(event.target.value);
    }
  };

  useEffect(() => {
    if (modify) {
      setSchoolName(modify?.name)
      setSchoolCode(modify?.codename);
      setAddressName(modify.address?.address);
      setCityName(modify?.address?.city);
      setZip(modify?.address?.zip_code);
      // setSelectedState(modify?.address?.state);
      setDistrictName(modify?.district?.name)
      setIsProgress(modify?.student_progress_enable);
      setIsMiddle(modify?.is_middle_school);
      setIsHidden(modify?.isHidden);
      
    }
  }, [modify]);

  const saveSchool = () => {
    // let obj;
    // if (isMiddle === true) {
    //   obj = {
    //     name: schoolName,
    //     codename: schoolCode,
    //     address: addressName,
    //     city: cityName,
    //     zip_code: zip,
    //     state: selectedState,
    //     is_middle_school: isMiddle,
    //     isHidden: isHidden,
    //   };
    // } else if (isProgress === true) {
    //   obj = {
    //     name: schoolName,
    //     codename: schoolCode,
    //     address: addressName,
    //     city: cityName,
    //     zip_code: zip,
    //     state: selectedState,
    //     student_progress_enable: isProgress,
    //     isHidden: isHidden,
    //   };
    // } else if (isMiddle === false || isProgress === false) {
    //   obj = {
    //     name: schoolName,
    //     codename: schoolCode,
    //     address: addressName,
    //     city: cityName,
    //     zip_code: zip,
    //     state: selectedState,
    //     isHidden: isHidden,
    //   };
    // }

    let obj = {
      name: schoolName,
      codename: schoolCode,
      address: {
        address:addressName,
        city: cityName,
        state: selectedState,
        zip_code: zip,
      },
      district: {
        id: districtID,
        name: districtName,
      },
      is_middle_school: isMiddle,
      student_progress_enable: isProgress,
      isHidden: isHidden,
    };

    GetAdminService.createSchool(obj)
      .then((data) => {
        console.log("data from the service", data);
        swal({
          title: "Successfully",
          text: "New school created successfully",
          icon: "success",
          dangerMode: true,
          buttons: {
            ok: true,
          },
          closeOnClickOutside: true,
        });
      })
      .catch((error) => {
        console.log("error from the service", error);
        swal({
          title: "Oops!!",
          text: error.response.data.message,
          icon: "warning",
          dangerMode: true,
          buttons: {
            cancel: true,
          },
          closeOnClickOutside: true,
        });
      });
    setOpenNewSchool(false);
  };

  const updateSchool = () => {
    let obj = {
        name: schoolName,
        codename: schoolCode,
        address: {
          address:addressName,
          city: cityName,
          state: selectedState,
          zip_code: zip,
        },
        district: {
          id: districtID || modify?.district?.id,
          name: districtName,
        },
        is_middle_school: isMiddle,
        student_progress_enable: isProgress,
        isHidden: isHidden,
      };

    GetAdminService.updateSchool(obj, schoolId)
      .then((data) => {
        console.log("data from sevices", data);
        swal({
          title: "Successfully",
          text: "School updated successfully",
          icon: "success",
          dangerMode: true,
          buttons: {
            ok: true,
          },
          closeOnClickOutside: true,
        });
      })
      .catch((error) => {
        console.log("error from services", error);
        swal({
          title: "Oops!!",
          text: error.response.data.message,
          icon: "warning",
          dangerMode: true,
          buttons: {
            cancel: true,
          },
          closeOnClickOutside: true,
        });
      });
       setOpenNewSchool(false);
  };

  return (
    <Fragment>
      <div className="goal-full-container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {modify ? (
            <p style={{ fontSize: "18px" }}>Modify School</p>
          ) : (
            <p style={{ fontSize: "18px" }}>New School</p>
          )}
          <FontAwesomeIcon
            icon={faTimes}
            style={{ opacity: "0.2", cursor: "pointer" }}
            onClick={() => setOpenNewSchool(false)}
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          <div style={{ position: "relative" }}>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <input
                type="text"
                style={{ width: "85%", padding: "6px", borderRadius: "3px" }}
                placeholder="School Name"
                value={schoolName}
                onChange={handleSchoolName}
                autoComplete="off"
              />
              <FontAwesomeIcon
                style={{
                  fontSize: "12px",
                  position: "absolute",
                  right: "75px",
                }}
                icon={faAsterisk}
                color="red"
              />
            </div>
            {!modify ?
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <input
                type="text"
                style={{
                  width: "85%",
                  padding: "6px",
                  borderRadius: "5px",
                }}
                placeholder="School Code Name"
                value={schoolCode}
                onChange={handleSchoolName}
                autoComplete="off"
              />
              <FontAwesomeIcon
                style={{
                  fontSize: "12px",
                  position: "absolute",
                  right: "75px",
                }}
                icon={faAsterisk}
                color="red"
              />
            </div>:""}
            {modify ?
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <input
                type="text"
                style={{
                  width: "85%",
                  padding: "6px",
                  borderRadius: "5px",
                }}
                placeholder="School Code Name"
                value={schoolCode}
                onChange={handleSchoolName}
                autoComplete="off"
                disabled={true}
              />
              <FontAwesomeIcon
                style={{
                  fontSize: "12px",
                  position: "absolute",
                  right: "75px",
                }}
                icon={faAsterisk}
                color="red"
              />
            </div>:""}
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <input
                type="text"
                style={{ width: "85%", padding: "6px", borderRadius: "3px" }}
                placeholder="Address First Line"
                value={addressName}
                onChange={handleAddressName}
                autoComplete="off"
              />
            </div>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <input
                type="text"
                style={{ width: "85%", padding: "6px", borderRadius: "3px" }}
                placeholder="City"
                value={cityName}
                onChange={handleCityName}
                autoComplete="off"
              />
            </div>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <input
                  ref={districtNameRef}
                  type="text"
                  style={{ width: "85%", padding: "6px", borderRadius: "3px" }}
                  placeholder="District Name"
                  value={districtName}
                  onChange={handleDistrictName}
                  autoComplete="off"
                />
                {showDistrictNameContainer && (
                  <div>
                    <ul
                      className="maintain-group-student-dropdown-menu"
                      style={{
                        width: "300px",
                        maxHeight: "400px",
                        overflowY: "scroll",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      {districtNameLoading && <CustomLoader />}
                      {districtNameList?.length > 0 &&
                        districtNameList.map((item, index) => (
                          <li
                            onClick={() => handleSelectedDistrictName(item)}
                            key={index}
                            className="flex admin-school-list cursor"
                          >
                            <span>{item.name}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
            </div>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
              }}
            >
              <select
                disabled
                value={selectedState}
                onChange={handleSelectState}
                style={{ width: "355px", padding: "4px", borderRadius: "3px" }}
              >
                {/* <option disabled selected value="">
                  State
                </option> */}
                {stateFromData?.map((item, index) => (
                  <option value={item?.name}>{item?.name}</option>
                ))}
              </select>
            </div>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <input
                type="number"
                style={{ width: "85%", padding: "6px", borderRadius: "3px" }}
                placeholder="Zip Code"
                value={zip}
                onChange={handleZip}
                autoComplete="off"
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <input
                type="checkbox"
                checked={isProgress}
                onChange={handleCheckboxProgress}
              />
              <span>&nbsp; Enable Student Progress for School</span>
              <br />
              <input
                type="checkbox"
                checked={isMiddle}
                onChange={handleCheckboxMiddle}
              />
              <span>&nbsp; Enable as Middle School</span>
              <br />
              <input
                type="checkbox"
                checked={isHidden}
                onChange={handleCheckboxHidden}
              />
              <span>&nbsp; Hidden</span>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          {modify && (
            <button
              className="cancel-admin-button"
              onClick={() => setOpenDeleteModal(true)}
            >
              <FontAwesomeIcon icon={faTrash} />
              &nbsp;Delete
            </button>
          )}
          {modify && (
            <button
              onClick={updateSchool}
              // disabled={!password}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "30px",
                position: "absolute",
                right: "20px",
                backgroundColor: !schoolName ? "#337ab7" : "#009900",
                outline: "none",
                border: "none",
                color: "white",
                padding: "5px 15px",
                marginTop: "20px",
                borderRadius: "3px",
              }}
            >
              <FontAwesomeIcon
                icon={faSave}
                color="white"
                style={{
                  cursor: "pointer",
                  marginRight: "5px",
                  marginTop: "3px",
                }}
              />
              Save
            </button>
          )}
          {!modify && (
            <button
              onClick={saveSchool}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "30px",
                position: "absolute",
                right: "20px",
                backgroundColor: !schoolName ? "#337ab7" : "#009900",
                outline: "none",
                border: "none",
                color: "white",
                padding: "5px 15px",
                marginTop: "20px",
                borderRadius: "3px",
              }}
            >
              <FontAwesomeIcon
                icon={faSave}
                color="white"
                style={{
                  cursor: "pointer",
                  marginRight: "5px",
                  marginTop: "3px",
                }}
              />
              Save
            </button>
          )}
        </div>
        {openDeleteModal && (
          <DeleteModal
            isOpen={openDeleteModal}
            setIsOpen={setOpenDeleteModal}
            fun1={deleteSchool}
          />
        )}
      </div>
    </Fragment>
  );
};
export default NewSchool;
