import { request } from "./Request";
import { routes } from "../constants/constant.route";
import { urlConstants } from "../utils/urlConstants";

const { GET_PERSONAL_INSLIGHTS } = routes.PERSONAL_INSLIGHTS;
const { API_URL } = urlConstants;

export default class PersonalInslightService {
  static getPIData() {
    return request("GET", `${API_URL}${GET_PERSONAL_INSLIGHTS}`, null, null, null, null);
  }
}