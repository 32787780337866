import React, { Fragment, useState, useEffect, useRef } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "sweetalert";
import { useSelector } from "react-redux";
const API_URL = process.env.REACT_APP_API_URL;

const UploadSchool = ({ setOpenUploadSchool }) => {
  const uploadSchoolRef = useRef();
  const [schoolFile, setSchoolFile] = useState();
  const token = useSelector((state) => state.UserReducer.userObj.token);

  const uploadSchoolHandler = () => {
    let file = uploadSchoolRef.current.files[0];
    setSchoolFile(file);
  };

  const uploadFileHandler = () => {
    let formData = new FormData();
    formData.append("csvfilename", schoolFile);
    fetch(`${API_URL}/api/v2/admin/upload-school`, {
      method: "POST",
      headers: {
        "x-authorization": `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => ({ data: response.json(), status: response.status }))
      .then((data) => {
        console.log("data from service", data);
        if (data.status === 200) {
          swal({
            title: "Successfully",
            text: "School uploaded successfully",
            icon: "success",
            dangerMode: true,
            buttons: {
              ok: true,
            },
            closeOnClickOutside: true,
          });
        } else {
          swal({
            title: "Oops!!",
            text: "Something went wrong",
            icon: "warning",
            dangerMode: true,
            buttons: {
              cancel: true,
            },
            closeOnClickOutside: true,
          });
        }
      setOpenUploadSchool(false)
      })
      .catch((error) => {
        console.log("error from the service", error);
        swal({
          title: "Oops!!",
          text: error.response.data.message || "Something went wrong",
          icon: "warning",
          dangerMode: true,
          buttons: {
            cancel: true,
          },
          closeOnClickOutside: true,
        });
      });
  };

  useEffect(() => {
    if (schoolFile) {
      uploadFileHandler();
    }
  }, [schoolFile]);

  return (
    <Fragment>
      <div className="goal-full-container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ fontSize: "18px" }}>Upload School from XLS file</p>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ opacity: "0.2", cursor: "pointer" }}
            onClick={() => setOpenUploadSchool(false)}
          />
        </div>
        <div>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "400",
              marginTop: "15px",
              borderBottom: "1px solid #e5e5e5",
            }}
          >
            Select File
          </p>
          <div>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <input
                type="file"
                ref={uploadSchoolRef}
                onChange={uploadSchoolHandler}
                accept=".xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default UploadSchool;
