import React, { Fragment, useState, useEffect, useLayoutEffect } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import QueryService from "../../../services/QueryService";
import { gradeData, stateData, gender } from "../../../constants/data";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
//import QueryService from '../../../services/QueryService';
const sfgv = {
  name: "Student First Generation",
  value: "student first generation",
  Selected: false,
};

const ucGreen = {
  name: "UC Green Requirement",
  value: "uc_status",
  Selected: false,
};

const containOrNotContain = [
  {
    name: "contains",
    value: "contains",
  },
  {
    name: "not",
    value: "not",
  },
];

const gpaOperators = [
  {
    name: ">",
    value: ">",
  },
  {
    name: "<",
    value: "<",
  },
  {
    name: ">=",
    value: ">=",
  },
  {
    name: "<=",
    value: "<=",
  },
];

const customStyles = {
  menu: (provided, state) => ({
    position: "relative",
  }),
  menuList: (provided, state) => ({
    height: 150,
    overflow: "scroll",
    width: "100%",
  }),
};

const AdvanceQueryCriteria = ({
  activitiesData,
  interestData,
  athleticsData,
  ethnicityData,
  academicsData,
  stemData,
  config,
  handleCheckBox,
  clearAll,
  selectedThirdParameter,
  setSelectedThirdParameter,
  item,
  onCloseAdvanceQuery,
  createConfigForGPAAdvanceQuery,
  createConfig,
}) => {
  const gradeFromData = JSON.parse(JSON.stringify(gradeData));
  const stateFromData = JSON.parse(JSON.stringify(stateData));
  const genderData = JSON.parse(JSON.stringify(gender));
  const [parameters, setParameters] = useState();
  const [selectedFirstParameter, setSelectedFirstParameter] = useState();
  const [thirdParameterOptions, setThirdParametersOptions] = useState();
  const [selectedSecondParameter, setSelectedSecondParameter] = useState();
  const [selectedConfig, setSelectedConfig] = useState();
  const [optionLabel, setOptionLabel] = useState();
  const [overallGPAValue, setOverallGPAValue] = useState();
  const [zipCode, setZipCode] = useState();
  const [schoolName, setSchoolName] = useState("");
  const [schoolArray, setSchoolArray] = useState();
  const [selectedSchool, setSelectedSchool] = useState([]);

  const handleSchoolValueChange = (e) => {
    console.log("e.target.value", e.target.value);
    setSchoolName(e.target.value);
  };
  const getParameters = async () => {
    QueryService.getAdvanceParameters()
      .then((data) => {
        console.log("data from the service", data);
        let filteredValue = data?.data?.object?.query_criteriamanual?.filter(
          (item) =>
            item.value !== "minimum goal" &&
            item.value !== "course concentration"
        );
        console.log("filteredValue from the config", filteredValue);
        setParameters(filteredValue);
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    getParameters();
  }, []);

  const handleSelectChange = async (e) => {
    let configgg;
    if (e.target.name === "first-parameter") {
      console.log("e.target.value", e.target.value);
      setSelectedFirstParameter(e.target.value);
      configgg =
        config && config.filter((item) => item.value === e.target.value);
      setSelectedConfig(configgg);
    } else if (e.target.name === "second-parameter") {
      setSelectedSecondParameter(JSON.parse(e.target.value));
    } else if (e.target.name === "gpa-operator") {
      let operatorvalue = JSON.parse(e.target.value);
      setSelectedSecondParameter(operatorvalue.value);
    }
  };

  const handleThirdOptionSelect = (value, deselect, type) => {
    console.log(
      "value from the thid option select",
      value,
      deselect,
      selectedFirstParameter
    );
    let lastItem = deselect.option || deselect.removedValue;
    if (deselect.removedValue) {
      lastItem.Selected = true;
    }
    setSelectedThirdParameter([...selectedThirdParameter, lastItem]);
    handleCheckBox(
      lastItem,
      selectedSecondParameter && selectedSecondParameter.value,
      selectedFirstParameter &&
        selectedFirstParameter !== "high school" &&
        selectedFirstParameter
    );
  };

  useEffect(() => {
    createConfigForGPAAdvanceQuery(selectedSecondParameter, overallGPAValue);
  }, [overallGPAValue]);

  const getSchoolName = () => {
    QueryService.getHighSchoolName(schoolName)
      .then((data) => {
        console.log("data from the school Name", data);
        setSchoolArray(data.data.objects);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    console.log(schoolName.length);
    if (schoolName && schoolName.length > 3) {
      getSchoolName();
    }
  }, [schoolName]);

  const convertToLabel = (value) => {
    // console.log("value from the convert to loabel", value)
    // let array = [];
    // value.map((item) => {
    //     console.log("item from the convert to label", item)
    //     let obj = {};
    //     obj.label = item.name || item.stem_name
    //     obj.value = item.value || item.id || item.code
    //     array.push(obj)
    // })
    setOptionLabel(value);
  };
  useEffect(() => {
    setOptionLabel();
    setSelectedThirdParameter([]);
    if (selectedFirstParameter === "grade") {
      setThirdParametersOptions(gradeFromData);
      convertToLabel(gradeFromData);
    } else if (selectedFirstParameter === "state") {
      setThirdParametersOptions(stateFromData);
      convertToLabel(stateFromData);
    } else if (selectedFirstParameter === "athletics") {
      setThirdParametersOptions(athleticsData);
      convertToLabel(athleticsData);
    } else if (selectedFirstParameter === "activities") {
      setThirdParametersOptions(activitiesData);
      convertToLabel(activitiesData);
    } else if (selectedFirstParameter === "gender") {
      setThirdParametersOptions(genderData);
      convertToLabel(genderData);
    } else if (selectedFirstParameter === "ethnicity") {
      setThirdParametersOptions(ethnicityData);
      convertToLabel(ethnicityData);
    } else if (selectedFirstParameter === "academics") {
      setThirdParametersOptions(academicsData);
      convertToLabel(academicsData);
    } else if (selectedFirstParameter === "interests") {
      setThirdParametersOptions(interestData);
      convertToLabel(interestData);
    } else if (selectedFirstParameter === "subject matter proficiency") {
      setThirdParametersOptions(stemData);
      convertToLabel(stemData);
    }
  }, [selectedFirstParameter]);

  useEffect(() => {
    clearAll();
  }, [selectedFirstParameter]);

  const selectSchool = (value) => {
    let array = [];
    array.push(value);
    setSelectedSchool([...selectedSchool, ...array]);
    setSchoolName("");
    setSchoolArray([]);
  };

  useEffect(() => {
    if (selectedThirdParameter && selectedThirdParameter.length > 0) {
      setSelectedSchool([]);
      setOverallGPAValue();
      setSelectedThirdParameter([]);
    }
  }, [selectedSecondParameter]);

  const removeSelectedSchool = (value, index) => {
    //let filteredSchoolValue = selectedSchool && selectedSchool.filter(item => item.name !== value.name)
    let removedValue = selectedSchool && selectedSchool.splice(index, 1);
    setSelectedSchool(removedValue);
  };

  useEffect(() => {
    console.log("secondParameter", selectedSecondParameter);
    handleCheckBox(
      selectedSchool,
      selectedSecondParameter && selectedSecondParameter.value,
      "high school"
    );
  }, [selectedSchool]);

  useEffect(() => {
    console.log("getting called the useEffect");
    if (selectedFirstParameter === "zip code") {
      handleCheckBox("", null, "zip code");
    }
  }, [selectedFirstParameter]);

  useEffect(() => {
    if (selectedFirstParameter === "student first generation") {
      handleCheckBox(
        sfgv,
        selectedSecondParameter?.value,
        "student first generation"
      );
    }
  }, [selectedSecondParameter]);

  useEffect(() => {
    if (selectedFirstParameter === "uc_status") {
      handleCheckBox(ucGreen, selectedSecondParameter?.value, "uc_status");
    }
  }, [selectedSecondParameter]);

  return (
    <Fragment>
      <div className="flex">
        <div
          onClick={() => onCloseAdvanceQuery(item)}
          className="advance-cancel-container cursor"
        >
          <CloseOutlinedIcon />
        </div>
        <div className="col-md-12 row">
          <div className="col-md-3">
            <select
              name="first-parameter"
              onChange={(e) => handleSelectChange(e)}
              style={{ height: "45px" }}
            >
              <option
                value="none"
                selected
                disabled
                hidden
              >{`Select an Option`}</option>
              {parameters &&
                parameters.map((item, indexschoolName) => (
                  <option value={item.value}>{item.name}</option>
                ))}
            </select>
          </div>
          <div className="col-md-3">
            {selectedFirstParameter &&
              selectedFirstParameter !== "overall gpa" &&
              selectedFirstParameter !== "zip code" && (
                <select
                  onChange={async (e) =>
                    setSelectedSecondParameter(JSON.parse(e.target.value))
                  }
                  name="second-parameter"
                  style={{ height: "45px", width: "100%" }}
                >
                  <option
                    value="none"
                    selected
                    disabled
                    hidden
                  >{`Select an Option`}</option>
                  {containOrNotContain &&
                    containOrNotContain.map((item, index) => (
                      <option key={index} value={JSON.stringify(item)}>
                        {item.name}
                      </option>
                    ))}
                </select>
              )}

            {selectedFirstParameter &&
              selectedFirstParameter !== "zip code" &&
              selectedFirstParameter === "overall gpa" && (
                <select
                  onChange={(e) => handleSelectChange(e)}
                  name="gpa-operator"
                  style={{ height: "45px", width: "100%" }}
                >
                  <option
                    value="none"
                    selected
                    disabled
                    hidden
                  >{`Select an Option`}</option>
                  {gpaOperators &&
                    gpaOperators.map((item, index) => (
                      <option key={index} value={JSON.stringify(item)}>
                        {item.name}
                      </option>
                    ))}
                </select>
              )}
          </div>
          <div className="col-md-3">
            {selectedFirstParameter &&
              selectedFirstParameter !== "overall gpa" &&
              selectedFirstParameter !== "zip code" &&
              selectedFirstParameter !== "high school" &&
              selectedFirstParameter !== "student first generation" &&
              selectedFirstParameter !== "uc_status" &&
              selectedSecondParameter && (
                <Select
                  className="select"
                  name="third-parameter"
                  options={optionLabel}
                  isMulti
                  isClearable={false}
                  onChange={(e, deselect) =>
                    handleThirdOptionSelect(e, deselect)
                  }
                  closeMenuOnSelect
                  isOptionSelected={false}
                  styles={customStyles}
                  getOptionLabel={(value) => value?.name}
                />
              )}
            {selectedFirstParameter &&
              selectedFirstParameter === "overall gpa" &&
              selectedSecondParameter && (
                <input
                  style={{ height: "100%" }}
                  type="number"
                  onChange={(e) => setOverallGPAValue(e.target.value)}
                  value={overallGPAValue}
                />
              )}

            {selectedFirstParameter &&
              selectedFirstParameter === "high school" &&
              selectedSecondParameter && (
                <div>
                  <input
                    placeholder="School Name"
                    style={{ height: "100%" }}
                    type="text"
                    onChange={(e) => handleSchoolValueChange(e)}
                    value={schoolName}
                  />
                  <div className="col-md-12">
                    {schoolArray &&
                      schoolArray.map((item, index) => {
                        return (
                          <div
                            onClick={() => selectSchool(item)}
                            className="high_school_container"
                            style={{ cursor: "pointer" }}
                            key={item.id}
                          >
                            {item.name}
                          </div>
                        );
                      })}
                  </div>
                  {selectedSchool && (
                    <div>
                      {selectedSchool &&
                        selectedSchool.map((item, index) => (
                          <span>
                            <span
                              style={{
                                background: "var(--secondary-color)",
                                width: "max-content",
                                margin: "5px 5px",
                                padding: "3px",
                                borderRadius: "3px",
                              }}
                              className="flex"
                            >
                              <p
                                style={{
                                  color: "white",
                                  fontSize: "10.5px",
                                  fontWeight: "bold",
                                }}
                                className="no-margin-bottom"
                              >
                                {item.name}
                              </p>
                              <span
                                onClick={() =>
                                  removeSelectedSchool(item, index)
                                }
                              >
                                <CloseIcon className="selected-school-close" />
                              </span>
                            </span>
                          </span>
                        ))}
                    </div>
                  )}
                </div>
              )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default AdvanceQueryCriteria;
