import React, { Fragment, useEffect, useState } from "react";
import SchoolItChart from "../../superCounselor/SchoolItChart";
import GetStudentService from "../../../services/SchoolIT/GetStudentService";
import CustomLoader from "../../customLoader/CustomLoader";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import swal from "sweetalert";
import StudentGoalService from "../../../services/StudentGoalService";
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const StudentStatistics = () => {
  const [user, setUser] = useState(
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  );
  const [loading, setLoading] = useState(false);
  const [differentGoalData, setDifferentGoalData] = useState();
  const [selectedGoal, setSelectedGoal] = useState();
  const [studentStatisticsData, setStudentStatisticsData] = useState();
  const [graphData, setGraphData] = useState();

  const getDetails = () => {
    let obj = {
      device_type: "web",
      mobile_os: "null",
      app_version: "null",
    };
    StudentGoalService.getStudentHistoryData(obj).then((data) => {
      console.log(data);
    });
  };

  useEffect(() => {
    getDetails();
  }, []);

  const getDifferentGoal = () => {
    setLoading(true);
    GetStudentService.getDifferentGoal(user?.school_id)
      .then((data) => {
        console.log("data from the service", data);
        setDifferentGoalData(data?.data?.data);
        setSelectedGoal(data?.data?.data[0].goal);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        swal({
          title: "Oops!!",
          text: "Something went wrong!! Please try again later.",
          icon: "warning",
          dangerMode: true,
          buttons: {
            cancel: true,
          },
          closeOnClickOutside: true,
        });
        console.log("error from the service", error);
      });
  };

  const handleGoalChange = (e) => {
    setSelectedGoal(e.target.value);
  };

  useEffect(() => {
    getStudentStatisticsData();
  }, []);

  const getStudentStatisticsData = () => {
    GetStudentService.getStudentStatisticsData(user?.school_id)
      .then((data) => {
        console.log("data from the getStudentStatisticsData", data);
        setStudentStatisticsData(data.data);
        getDifferentGoal();
      })
      .catch((error) => {
        console.log("error from the getStudentStatisticsData", error);
      });
  };

  useEffect(() => {
    let data;
    if (studentStatisticsData && selectedGoal) {
      data = studentStatisticsData?.goal_data?.filter(
        (item) => item?.goal_name === selectedGoal
      );
      let obj = {
        active_students: studentStatisticsData?.active_students,
        goal_data: data,
        green_stu_count:
          data[0]?.Freshman[1] +
          data[0].Seniors[1] +
          data[0]?.Sophmores[1] +
          data[0]?.Juniors[1],
        total_students: studentStatisticsData?.total_students,
      };
      setGraphData(obj);
    }
  }, [selectedGoal]);

  // console.log("data from the useEffect", studentStatisticsData);
  // console.log("SG", selectedGoal);

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
      {loading ? (
        <CustomLoader />
      ) : (
        <div>
          <div
            className="flex"
            style={{ paddingTop: "15px", paddingLeft: "15px"}}
          >
            <div className="flex" style={{width:"100%", paddingRight:"20px"}}>
              <img style={{ width: "45px", height: "45px" }} src={IMG_URL+"Goal_Icon.jpg"} />
              <div>
                <p
                  style={{ marginLeft: "15px", fontSize: "18px" }}
                  className="no-margin-bottom"
                >
                  STUDENT
                </p>
                <p
                  style={{ marginLeft: "15px", fontSize: "18px" }}
                  className="no-margin-bottom"
                >
                  STATISTICS
                </p>
              </div>
              <select
                style={{
                  height: "40px",
                  marginLeft:"auto"
                }}
                value={selectedGoal}
                onChange={(e) => handleGoalChange(e)}
              >
                <option disabled selected>
                  --Select Goal--
                </option>
                {differentGoalData?.map((item) => (
                  <option value={item?.goal}>{item?.goal}</option>
                ))}
              </select>
            </div>
          </div>

          {differentGoalData && (
            <SchoolItChart
              studentGoal={selectedGoal}
              studentStatisticsData={graphData}
            />
          )}
        </div>
      )}
      </div>
      </div>
    </Fragment>
  );
};
export default StudentStatistics;
