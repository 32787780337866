import { request } from "../Request";
import { routes } from "../../constants/constant.route";
// import { PopoverBody } from "reactstrap";
const API_URL = process.env.REACT_APP_API_URL;

export default class GetAdminService {
  static getRecruiter(obj) {
    return request(
      "GET",
      `${API_URL}${routes.admin.getRecruiter}?college_id=${obj.college}&page=${
        obj.page
      }&role=${obj.role}`,
      null,
      null,
      null,
      null
    );
  }

  static getExportData(obj) {
    return request(
      "GET",
      `${API_URL}${routes.admin.getExportRecruiter}?page=${obj.page}`,
      null,
      null,
      null,
      null
    );
  }

  static getAdminSchoolIT(obj) {
    return request(
      "GET",
      `${API_URL}${routes.admin.getAdminSchoolIT}?school_id=${
        obj.school
      }&page=${obj.page}&role=${obj.role}`,
      null,
      null,
      null,
      null
    );
  }

  static createSchool(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.createSchool}`,
      null,
      body,
      null,
      null
    );
  }

  static updateSchool(body, id) {
    return request(
      "PUT",
      `${API_URL}${routes.admin.getUpdateSchool}${id}/`,
      null,
      body,
      null,
      null
    );
  }

  static getDeleteSchool(id) {
    return request(
      "DELETE",
      `${API_URL}${routes.admin.getDeleteSchool}?id=${id}`,
      null,
      null,
      null,
      null
    );
  }

  static getSchoolsList(value) {
    return request(
      "GET",
      `${API_URL}${routes.admin.getSchoolsList}?q=${value}`,
      null,
      null,
      null,
      null
    );
  }

  static getClassesList(value) {
    return request(
      "GET",
      `${API_URL}${routes.admin.getRefClassesList}?q=${value}`,
      null,
      null,
      null,
      null
    );
  }

  static getAllSchoolsList(value) {
    return request(
      "GET",
      `${API_URL}${routes.admin.getAllSchoolsList}?school=${value}`,
      null,
      null,
      null,
      null
    );
  }

  static getCounselorsList(value, role, school) {
    return request(
      "GET",
      `${API_URL}${
        routes.admin.getCounselorsList
      }?q=${value}&role=${role}&school_id=${school}`,
      null,
      null,
      null,
      null
    );
  }

  static getCSVData(schoolIds) {
    return request(
      "GET",
      `${API_URL}${routes.admin.getCSVData}?school_id=${schoolIds}`,
      null,
      null,
      null,
      null
    );
  }
  // else {
  //   return request(
  //     "GET",
  //     `${API_URL}${
  //       routes.admin.getCSVData
  //     }?offset=${offset}&role=${role}&school=${schoolIds}`,
  //     null,
  //     null,
  //     null,
  //     null
  //   );
  // }
  // }

  static getCollegesList(value) {
    return request(
      "GET",
      `${API_URL}${routes.admin.getCollegesList}?q=${value}`,
      null,
      null,
      null,
      null
    );
  }

  static getDistrictsList(value) {
    return request(
      "GET",
      `${API_URL}${routes.admin.getDistrictsList}?q=${value}`,
      null,
      null,
      null,
      null
    );
  }

  static getStudentStatistics() {
    return request(
      "GET",
      `${API_URL}${routes.admin.getStudentStatistics}`,
      null,
      null,
      null,
      null
    );
  }

  static getStudentStatisticsExcel() {
    return request(
      "GET",
      `${API_URL}${routes.admin.getStudentStatisticsExcel}`,
      null,
      null,
      null,
      null
    );
  }

  static createStudentsSearch(obj) {
    if (obj.q) {
      return request(
        "GET",
        `${API_URL}${routes.admin.searchStudent}?role=${obj.role}&school_id=${
          obj.school
        }&q=${obj.q}&page=${obj.page}`,
        null,
        null,
        null,
        null
      );
    } else {
      return request(
        "GET",
        `${API_URL}${routes.admin.searchStudent}?role=${obj.role}&school_id=${
          obj.school
        }&page=${obj.page}`,
        null,
        null,
        null,
        null
      );
    }
  }

  static sendCounselorEmail(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.sendEmail}`,
      null,
      body,
      null,
      null
    );
  }

  // static getSchoolsList(value) {
  //   return request(
  //     "GET",
  //     `${API_URL}${routes.admin.getSchoolsList}?q=${value}`,
  //     null,
  //     null,
  //     null,
  //     null
  //   );
  // }

  // static getCollegesList(value) {
  //   return request(
  //     "GET",
  //     `${API_URL}${routes.admin.getCollegesList}?q=${value}`,
  //     null,
  //     null,
  //     null,
  //     null
  //   );
  // }

  // static getDistrictsList(value) {
  //   return request(
  //     "GET",
  //     `${API_URL}${routes.admin.getDistrictsList}?q=${value}`,
  //     null,
  //     null,
  //     null,
  //     null
  //   );
  // }

  // static getStudentStatistics() {
  //   return request(
  //     "GET",
  //     `${API_URL}${routes.admin.getStudentStatistics}`,
  //     null,
  //     null,
  //     null,
  //     null
  //   );
  // }

  static getCannedMessage(id) {
    return request(
      "GET",
      `${API_URL}${routes.admin.getCannedMessage}/${id}`,
      null,
      null,
      null,
      null
    );
  }

  // canned Messages

  static createCannedMessage(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.cannedMessage}`,
      null,
      body,
      null,
      null
    );
  }

  static getMessage(id) {
    return request(
      "GET",
      `${API_URL}${routes.admin.cannedMessage}${id}`,
      null,
      null,
      null,
      null
    );
  }

  static translateText(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.translateText}`,
      null,
      body,
      null,
      null
    );
  }

  static cancelNewCannedMessage(body, id) {
    return request(
      "DELETE",
      `${API_URL}${routes.admin.getCannedMessage}/${id}`,
      null,
      body,
      null,
      null
    );
  }

  static deleteCannedMessage(id) {
    return request(
      "DELETE",
      `${API_URL}${routes.admin.cannedMessage}/${id}`,
      null,
      null,
      null,
      null
    );
  }

  static saveCannedMessage(body, id) {
    return request(
      "PATCH",
      `${API_URL}${routes.admin.cannedMessage}/${id}`,
      null,
      body,
      null,
      null
    );
  }

  static getCannedPage(obj) {
    // static getCannedPage(obj) {
      return request(
        "GET",
        `${API_URL}${routes.admin.GET_CANNED_PAGE}?college_id=${obj.school}&page=${obj.page}`,
    // return request(
    //   "POST",
    //   `${API_URL}${routes.admin.GET_CANNED_PAGE}`,
      null,
      null,
      null,
      null
    );
  }

  // admin counselor page

  static postAdminPage(obj) {
    return request(
      "GET",
      `${API_URL}${routes.admin.adminPage}?school_id=${obj.school}&page=${
        obj.page
      }&role=${obj.role}`,
      null,
      null,
      null,
      null
    );
  }

  static createCourse(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.getSelectedCourse}`,
      null,
      body,
      null,
      null
    );
  }

  static updateCourse(body, id) {
    return request(
      "PATCH",
      `${API_URL}${routes.admin.getSelectedCourse}/${id}`,
      null,
      body,
      null,
      null
    );
  }

  static getModifyCounselor(id) {
    return request(
      "GET",
      `${API_URL}${routes.admin.counselorAdmin}?counselor=${id}`,
      null,
      null,
      null,
      null
    );
  }

  static getModifyRecruiter(id) {
    return request(
      "GET",
      `${API_URL}${routes.admin.recruiterAdmin}/${id}`,
      null,
      null,
      null,
      null
    );
  }

  static saveCounselor(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.getCounselorsList}`,
      null,
      body,
      null,
      null
    );
  }

  static updateCounselor(body) {
    return request(
      "PUT",
      `${API_URL}${routes.admin.getCounselorsList}`,
      null,
      body,
      null,
      null
    );
  }

  static deleteCounslor(id) {
    return request(
      "DELETE",
      `${API_URL}${routes.admin.getCounselorsList}?id=${id}`,
      null,
      null,
      null,
      null
    );
  }

  static deleteSchoolIT(body, id) {
    return request(
      "DELETE",
      `${API_URL}${routes.admin.deleteSchoolIT}?id=${id}`,
      null,
      body,
      null,
      null
    );
  }

  static deleteRecruiter(id) {
    return request(
      "DELETE",
      `${API_URL}${routes.admin.createRecruiter}?id=${id}`,
      null,
      null,
      null,
      null
    );
  }

  // send invites
  // static getCounselorsList(value, role, school) {
  //   return request(
  //     "GET",
  //     `${API_URL}${
  //       routes.admin.getCounselorsList
  //     }?q=${value}&role=${role}&school=${school}`,
  //     null,
  //     null,
  //     null,
  //     null
  //   );
  // }

  // static getCSVData(schoolIds, offset, role, limit) {
  //   if (limit) {
  //     return request(
  //       "GET",
  //       `${API_URL}${
  //         routes.admin.getCSVData
  //       }?offset=${offset}&role=${role}&school=${schoolIds}&limit=${limit}`,
  //       null,
  //       null,
  //       null,
  //       null
  //     );
  //   } else {
  //     return request(
  //       "GET",
  //       `${API_URL}${
  //         routes.admin.getCSVData
  //       }?offset=${offset}&role=${role}&school=${schoolIds}`,
  //       null,
  //       null,
  //       null,
  //       null
  //     );
  //   }
  // }

  // static createStudentsSearch(body) {
  //   return request(
  //     "POST",
  //     `${API_URL}${routes.admin.searchStudent}`,
  //     null,
  //     body,
  //     null,
  //     null
  //   );
  // }

  static sendInvites(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.sendInvites}`,
      null,
      body,
      null,
      null
    );
  }
  // static sendCounselorEmail(body) {
  //   return request(
  //     "POST",
  //     `${API_URL}${routes.admin.sendEmail}`,
  //     null,
  //     body,
  //     null,
  //     null
  //   );
  // }

  // Update Goals for School
  static goalBySchool(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.goalBySchool}`,
      null,
      body,
      null,
      null
    );
  }

  static goalPublish(school) {
    return request(
      "GET",
      `${API_URL}${routes.admin.goalPublish}?manage=${school}`,
      null,
      null,
      null,
      null
    );
  }

  static updateSchoolGoal(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.updateSchoolGoal}`,
      null,
      body,
      null,
      null
    );
  }
  // students without Counselor
  static withoutCounselor(page, school) {
    return request(
      "GET",
      `${API_URL}${
        routes.admin.withoutCounselor
      }?page=${page}&school_id=${school}`,
      null,
      null,
      null,
      null
    );
  }

  static districtPage(obj) {
    return request(
      "GET",
      `${API_URL}${routes.admin.districtPage}?district=${obj.district}`,
      null,
      null,
      null,
      null
    );
  }

  static districtSftpPage(obj) {
    return request(
      "GET",
      `${API_URL}${routes.admin.districtSftpPage}?district_id=${obj.district}`,
      null,
      null,
      null,
      null
    );
  }

  static subscribeContract(school, body) {
    return request(
      "PUT",
      `${API_URL}${routes.admin.subscribeContract}${school}/`,
      null,
      body,
      null,
      null
    );
  }

  static updateRecruiter(body) {
    return request(
      "PUT",
      `${API_URL}${routes.admin.updateRecruiter}`,
      null,
      body,
      null,
      null
    );
  }

  static updateSchoolIT(body, id) {
    return request(
      "PUT",
      `${API_URL}${routes.admin.updateSchoolIT}`,
      null,
      body,
      null,
      null
    );
  }

  static saveRecruiter(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.createRecruiter}`,
      null,
      body,
      null,
      null
    );
  }

  static saveSchoolIT(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.createSchoolIT}`,
      null,
      body,
      null,
      null
    );
  }

  static getAllCollegesList() {
    return request(
      "GET",
      `${API_URL}${routes.admin.getCollegesList}`,
      null,
      null,
      null,
      null
    );
  }

  static getSchoolsListForCollege(value) {
    return request(
      "GET",
      `${API_URL}${routes.admin.getSchoolsList}?school=${value}`,
      null,
      null,
      null,
      null
    );
  }

  static getGoalDetail(value) {
    return request(
      "GET",
      `${API_URL}${routes.admin.getGoalDetail}?goal=${value}`,
      null,
      null,
      null,
      null
    );
  }

  static saveCollege(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.getCollegesList}`,
      null,
      body,
      null,
      null
    );
  }

  static updateCollege(body, id) {
    return request(
      "PUT",
      `${API_URL}${routes.admin.getCollegesList}`,
      null,
      body,
      null,
      null
    );
  }

  static deleteCollege(body, id) {
    return request(
      "DELETE",
      `${API_URL}${routes.admin.getCollegesList}`,
      null,
      body,
      null,
      null
    );
  }

  static goalAdminPage(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.goalAdminPage}`,
      null,
      body,
      null,
      null
    );
  }

  static coursePage(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.coursePage}`,
      null,
      body,
      null,
      null
    );
  }

  static goalPublished = (body) => {
    return request(
      "POST",
      `${API_URL}${routes.admin.goalAdminPublish}`,
      null,
      body,
      null,
      null
    );
  };

  static coursePublished = (body) => {
    return request(
      "POST",
      `${API_URL}${routes.admin.courseAdminPublish}`,
      null,
      body,
      null,
      null
    );
  };

  static duplicateAdminGoal(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.duplicateAdminGoal}`,
      null,
      body,
      null,
      null
    );
  }

  static goalFieldForCreateGoal(limit) {
    return request(
      "GET",
      `${API_URL}${routes.admin.goalField}?limit=${limit}`,
      null,
      null,
      null,
      null
    );
  }

  static listOfCourse(broadcastcourse) {
    return request(
      "GET",
      `${API_URL}${routes.admin.listOfCourse}?goalcreate=${broadcastcourse}`
    );
  }

  static getCourse(id) {
    return request(
      "GET",
      `${API_URL}${routes.admin.getCourse}/${id}`,
      null,
      null,
      null,
      null
    );
  }

  static goalField() {
    return request(
      "GET",
      `${API_URL}${routes.admin.goalRequiredField}`,
      null,
      null,
      null,
      null
    );
  }

  static goalFieldData() {
    return request(
      "GET",
      `${API_URL}${routes.admin.goalFieldData}`,
      null,
      null,
      null,
      null
    );
  }

  static deleteGoalFieldData(id) {
    return request(
      "DELETE",
      `${API_URL}${routes.admin.goalFieldData}?id=${id}`,
      null,
      null,
      null,
      null
    );
  }

  static saveType(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.saveType}`,
      null,
      body,
      null,
      null
    );
  }

  static goalExpressionData() {
    return request(
      "GET",
      `${API_URL}${routes.admin.goalExpressionData}`,
      null,
      null,
      null,
      null
    );
  }

  static deletegoalExpresssionData(id) {
    return request(
      "DELETE",
      `${API_URL}${routes.admin.goalExpressionData}?id=${id}`,
      null,
      null,
      null,
      null
    );
  }

  static saveExpression(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.saveExpression}`,
      null,
      body,
      null,
      null
    );
  }

  static operatorShowData() {
    return request(
      "GET",
      `${API_URL}${routes.admin.operatorShowData}`,
      null,
      null,
      null,
      null
    );
  }

  static deletegoalOperatorData(id) {
    return request(
      "DELETE",
      `${API_URL}${routes.admin.operatorShowData}?id=${id}`,
      null,
      null,
      null,
      null
    );
  }

  static saveOperator(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.saveOperator}`,
      null,
      body,
      null,
      null
    );
  }

  static arithemeticShowData() {
    return request(
      "GET",
      `${API_URL}${routes.admin.arithemeticShowData}`,
      null,
      null,
      null,
      null
    );
  }

  static deletearithematicData(id) {
    return request(
      "DELETE",
      `${API_URL}${routes.admin.arithemeticShowData}?id=${id}`,
      null,
      null,
      null,
      null
    );
  }

  static saveArthOptr(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.saveArthOptr}`,
      null,
      body,
      null,
      null
    );
  }

  static typeShowData() {
    return request(
      "GET",
      `${API_URL}${routes.admin.typeShowData}`,
      null,
      null,
      null,
      null
    );
  }

  static deletetypeData(id) {
    return request(
      "DELETE",
      `${API_URL}${routes.admin.typeShowData}?id=${id}`,
      null,
      null,
      null,
      null
    );
  }

  static saveField(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.saveField}`,
      null,
      body,
      null,
      null
    );
  }

  static deletequeryData(id) {
    return request(
      "DELETE",
      `${API_URL}${routes.admin.queryShowData}?id=${id}`,
      null,
      null,
      null,
      null
    );
  }

  static queryCriteriasShowData() {
    return request(
      "GET",
      `${API_URL}${routes.admin.queryCriteriasShowData}`,
      null,
      null,
      null,
      null
    );
  }

  static saveCriteria(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.saveCriteria}`,
      null,
      body,
      null,
      null
    );
  }

  static getCourseList(value) {
    return request(
      "GET",
      `${API_URL}${routes.admin.getCourseList}?q=${value}`,
      null,
      null,
      null,
      null
    );
  }

  static saveCourse(body) {
    return request(
      "POST",
      `${API_URL}${routes.admin.saveCourse}`,
      null,
      body,
      null,
      null
    );
  }
}
