/* eslint-disable import/prefer-default-export */
// const crmPointV2Prefix = '/api/v2/crm-points';

export const routes = {

  configRecruiter: {
    get_ethnicities: "/api/v2/recruiter/get_configuration_list/",
  },
  
  AUTH: {
    // FORGOT_PASSWORD: "/api/v1/user/forgot-password/",

    // LOGIN: "/api/ua/login/",
    LOGIN: "/api/v2/auth/login/",
    RESET_PASSWORD: "/api/v2/auth/forgot_password/",  
    CHANGE_PASSWORD: "/api/v2/auth/change_password/",


    // REFRESH_TOKEN: "/api/user/generate_access_token",
    // PASSWORD_VERIFY_TOKEN: "/api/v1/user/password-verify-token",
    // RESET_PASSWORD: "/api/v1/user/forgot-password/confirm/",
    // VERIFY_OTP: '/api/user/auth/verify_otp',
    // RESET_OTP: '/api/user/auth/send_otp'
    // LOGOUT: "/api/v1/user/logout-from-all",
  },
  STUDENT: {
    // GOAL: "/api/v1/goal",
    STUDENT_GOAL: "/api/v2/goal/student/brief_status/",
    STUDENT_HISTORY: "/api/v2/goal/student/history/",
    STUDENT_SAVE_REFLECTION: "/api/v2/goal/student/student_reflections/",
     STUDENT_PDF_REFLECTION: "/api/v2/goal/student/pdf/",
  },
  PORTFOLIO: {
    CONFIGURELIST: "/api/v2/goal/student/configuration/",
    GET_INTEREST: "/api/v2/goal/student/interests/",
    SAVE_INTEREST: "/api/v2/goal/student/interests/",
    TRANSLATE: "/api/v2/student/translate/",
    GET_ACADEMICS: "/api/v2/goal/student/academics/",
    GET_ACTIVITIES: "/api/v2/goal/student/activities/",
    GET_ATHLETICS: "/api/v2/goal/student/athletics/",
    SAVE_ACTIVITIES: "/api/v2/goal/student/activities/",
    SAVE_ACADEMICS: "/api/v2/goal/student/academics/",
    SAVE_ATHLETICS: "/api/v2/goal/student/athletics/",
    GET_REFLECTION: "/api/v2/goal/student/student_reflections/",
  },
  TRANSLATE: {
    TRANSLATE: "/api/v2/goal/student/translate/",
    TRANSLATE_CURR: "/api/v2/student/translate_curr/",
  },
  PERSONAL_INSLIGHTS: {
    GET_PERSONAL_INSLIGHTS: "/api/v2/goal/student/personal_insights/"
  },
  PROFILE: {
    UPDATE_PROFILE: "/api/v2/goal/student/update_profile/",
    PROFILE_UPLOAD: "/api/v2/goal/student/attachment/",
  },
  CALENDER: {
    CALENDER: "/api/v2/goal/student/calendar/",
  },
  MESSAGE: {
    GET_UNREAD_MESSAGE_COUNT: "/api/v2/comm/notification/",  // POST
    GET_MESSGAE_LIST_PER_USER: "/api/v2/comm/received_message/", // POST
    SEND_MESSAGE: "/api/v2/comm/sent_message/",  //POST
    READ_COUNT_DATE: "/api/v2/comm/message_read/", //POST
  },
  SCHOOL: {
    GET_SCHOOL_LIST: "/api/v2/auth/get_college/",  // GET
  },
  STUDENT_REGISTER: {
    STUDENT_DETAIL_CHECK: "/api/v2/auth/user_details_check/",  // POST
    REGISTER_COUNST: "/api/v2/register/counst", // GET
    CREATE_STUDENT_ACCOUNT: "/api/v2/auth/user_details_update/",  // POST
    COUNTRY_CHECK: `/api/v2/ua/countries/`,
    STATE_CHECK: `/api/v2/auth/states/`,
    SEMESTER_DATA: `/api/v2/auth/get_semesters/`,
    PERSONAL_EMAIL_CHECK: `/api/v2/auth/signup_personal/`,
    PERSONAL_OTP_VERIFY: `/api/v2/auth/verify_otp/`,
  },
  GET_DISTRICT: "/api/v2/ua/get_district/",
  GET_DISTRICT_BASE_SCHOOL: "/api/v2/ua/get_school/",
  PERSONAL_STUDENT_REGISTER: "/api/v2/auth/collect_personal_info/",


  auth: {
    // LOGOUT: "/api/ua/logout/",
    LOGOUT: "/api/v2/auth/logout/",
  },
  updateProfile: {
    UPDATE_PROFILE: "/api/v2/goal/student/update_profile/",
  },
  msg: {
    GET_MESSAGE_LIST: "/api/v2/discussion/page",
    GET_TOTAL_MESSAGE_LIST: "/api/v2/comm/sender_count/",
  },
  getCannedMsg: {
    GET_CANNED_MSG: "/api/v2/recruiter/message_template/",
  },
  getListOfStudent: {
    GET_LIST: "/api/v2/recruiter/search_user/",
  },
  sendMessage: {
    SEND_MESSAGE: "/api/v2/comm/sent_message/",
  },
  sendSftp: {
    SEND_SFTP: "/api/v2/sftp/sftp_process/",
  },
  attachment: {
    ATTACHMENT: "/api/v2/recruiter/message_attachment/",
  },
  deleteMessage: {
    DELETE_REC_MESSAGE: "/api/v2/recruiter/delete_message/",
    DELETE_MESSAGE: "/api/v2/counsellor/deleted_message/",
    DELETE_GROUP_MESSAGE: "/api/v2/counsellor/message_group/"
  },
  getGender: {
    GET_GENDER: "/api/v2/configure?list_name=gender",
  },

  //siembra
  getEthnicity: {
    GET_ETHNICITIES: "/api/v2/translate/ethnicity",
  },
  //school_It
  getEthnicity: {
    GET_ETHNICITIES_IT: "/api/v2/configure?list_name=ethnicity",
  },
  
   //siembra
   getEthnicity: {
    GET_ETHNICITIES: "/api/v2/translate/ethnicity",
  },
   

  //CCC
  getCCC: {
    GET_CCC: "/api/v2/recruiter/ccc_query_page/",
  },

  //International
  getInternational: {
    GET_INTERNATIONAL: "/api/v2/recruiter/intl_configuration_list/",
  },


  //Siembra
  getAAAI: {
    GET_AAAI: "/api/v2/configure/alladvancedQuery",
  },

  //CCC
  getAAAIS: {
    GET_AAAIS: "/api/v2/recruiter/ccc_query_page/"
  },

  // config: {
  //   getConfig: "/api/v1/configure?limit=200&name=configuration_list",
  // },
  getQueryResult: {
    getQueryResult: "/api/v2/result/query_result",
  },
  getAdvanceParameters: {
    getAdvanceParameters: "/api/v2/recruiter/get_configuration_list/",
  },
  getResultDisplay: {
    getResultDisplay: "/api/v2/result/result_display",
  },

  //custom
  getResultPage: {
    getResultPage: "/api/v2/recruiter/high_school_query/",
  },
//fav premium student
getFavoritePage: {
  getFavoritePage: "/api/v2/recruiter/myfavstudent/",
},
  //college
  getCollegeResultPage: {
    getCollegeResultPage: "/api/v2/recruiter/ccc_query/",
  },

  //international
  getInternationalResultPage: {
    getInternationalResultPage: "/api/v2/recruiter/international_student_query/",
  },

  
  getSchoolPage: {
    getSchoolQuery: "/api/v2/recruiter/query_manager/",
  },
  deleteQuery: {
    deleteQuery: "/api/v2/result/delete",
  },
  duplicateQueryForSaved: {
    duplicateQueryForSaved: "/api/v2/result/duplicate",
  },
  saveQuery: {
    saveQuery: "/api/v2/result/save",
  },
  saveQueryForCollege: {
    saveQueryForCollege: "/api/v2/recruiter/query_manager/",
  },
  highSchoolName: {
    highSchoolName: "/api/v2/recruiter/get_school/",
  },
  viewQuery: {
    viewQuery: "/api/v2/recruiter/query_manager/",
  },
  duplicateQuery: {
    duplicateQuery: "/api/v2/query/duplicate",
  },
  deleteForSchoolQuery: {
    deleteForSchoolQuery: "/api/v2/recruiter/query_manager/",
  },
  campaignPage: {
    campaignPage: "/api/v2/campaign/page",
  },
  bannerPage: {
    bannerPage: "/api/v2/banner/page",
  },
  // getCampaignResultTable: {
  //   getCampaignResultTable: "/api/v1/result/page",
  // },
  getCampaignQuery: {
    getCampaignQuery: "/api/v2/query",
  },
  getQuery: {
    getCampaignQuery: "/api/v2/query",
  },
  getCampaignBannerQuery: {
    getCampaignBannerQuery: "/api/v2/banner",
  },
  createCampaign: {
    createCampaign: "/api/v2/campaign/save",
  },
  deleteCampaign: {
    deleteCampaign: "/api/v2/campaign/delete",
  },
  editCampaign: {
    editCampaign: "/api/v2/campaign/",
  },
  deleteForBannerRequirement: {
    deleteForBannerRequirement: "/api/v2/banner/requirements",
  },
  uploadBannerImage: {
    uploadBannerImage: "/api/v2/banner/upload-image",
  },
  createBanner: {
    createBanner: "/api/v2/banner/create",
  },
  getMessageTemplate: {
    getMessageTemplate: "/api/v2/recruiter/message_template/",
  },
  deleteMessageTemplate: {
    deleteMessageTemplate: "/api/v2/recruiter/message_template/",
  },
  cannedMessageForTemplate: {
    cannedMessageForTemplate: "/api/v2/recruiter/message_template/",
  },
  cannedMessageTemplateForSuperCounselor: {
    cannedMessageTemplateForSuperCounselor: "/api/v2/recruiter/message_template/",
  },
  save_via_result: {
    save_via_result: "/api/v2/result/save",
  },
  save_via_query: {
    save_via_query: "/api/v2/result/save_via_query",
  },
  //custom-query
  export_result: {
    export_result: "/api/v2/recruiter/export_csv_high_school/",
  },
  //ccc
  export_result_ccc: {
    export_result_ccc: "/api/v2/recruiter/ccc_export_csv/",
  },
  //international
  export_result_international: {
    export_result_international: "/api/v2/recruiter/international_export/",
  },
  //For Student
  login: {
    login: "/api/v2/ua/login",
  },
  getLadder: {
    getLadder: "/api/v2/configure/ladder",
  },
  saveLadder: {
    saveLadder: "/api/v2/configure/ladder",
  },
  getAcademics: {
    getAcademics: "/api/v2/configure/academics",
  },
  getAthletics: {
    getAthletics: "/api/v2/configure/athletics",
  },
  getActivities: {
    getActivities: "/api/v2/configure/activities",
  },
  configureList: {
    configureList: "/api/v2/configure?limit=200",
  },
  saveActivities: {
    saveActivities: "/api/v2/configure/activitysave",
  },
  saveAcademics: {
    saveAcademics: "/api/v2/configure/academicsave",
  },
  saveAthletics: {
    saveAthletics: "/api/v2/configure/athleticssave",
  },
  getInterest: {
    getInterest: "/api/v2/configure/interest",
  },
  saveInterest: {
    saveInterest: "/api/v2/configure/interestsave",
  },
  translate: {
    translate: "/api/v2/student/translate/",
  },
  studentgoal: {
    requirement: "/api/v2/studentgoal/requirement",
    studentgoal: "/api/v2/student/student_status/",
    goal: "/api/v2/counsellor/goal_criteria/",
    goaldata: "/api/v2/counsellor/student_status_counselor/",
    fail_list: "/api/v2/counsellor/fail_list/ ",
    getGroupPage: "/api/v2/counsellor/manage_group/",
    myfavStudent: "/api/v2/recruiter/myfavstudent/",
    downloadTrans: "/api/v2/recruiter/download/",
    deleteTrans: "/api/v2/recruiter/myfavstudent/",
    deleteSpecificGroup:"/api/v2/counsellor/manage_group/",
  },
  getSingleMessageForStudent: {
    gettingIdForMessageList: "/api/v2/discussion/pagenew",
    getSingleMessageForStudent: "/api/v2/discussion",
    getUnreadMessageCount: "/api/v2/comm/notification/",
    date: "/api/v2/comm/message_read/",
  },
  calendar: {
    calender: "/api/v2/goal/student/calendar/",
  },
  profileUpload: {
    profileUpload: "/api/v2/me/attachment",
  },
  query_result_pagination: {
    query_result_pagination: "/api/v2/comm/sent_message/",
  },
  
  // Counselor Routes
  counselor: {
    getMsgListCount:"/api/v2/counsellor/deleted_message/",
    getStudentForCounselor: "/api/v2/counsellor/counsellor_dashboard/",
    addToWatchlist: "/api/v2/counsellor/watchlist/",
    studentgoal_requirement: "/api/v2/counsellor/requirement/",
    studentmigrate_status: "/api/v2/counsellor/migrate_status/",
    studentdataperId: "/api/v2/admin/student_list/",
    goalPublishManage: "/api/v2/counsellor/status_calculation/",
    getStudent: "/api/v2/counsellor/counsellor_dashboard/",
    saveGroup: "/api/v2/counsellor/manage_group/",
    currentStudent: "/api/v2/counsellor/counsellor_dashboard/",
    allStudentPDF: "/api/v2/student/pdf/",
    send_single_invite: "/api/v2/counsellor/send_single_invite/",
    add_existing_student_list: "/api/v2/counsellor/counsellor_dashboard/",
    add_existing_student: "/api/v2/counsellor/add_student/",
    getStudentListForCounselor: "/api/v2/me/students",
    getMsgList1:"/api/v2/message-group/page/",
    getMsgList: "/api/v2/comm/sender_count/",
    reply: "/api/v2/discussion",
    getCollegeName: "/api/v2/admin/college_view/",
    getRecruiterName: "/api/v2/recruiter/search_user/",
    getData: "/api/v2/counsellor/download_statistics/",
    getGroupName:"/api/v2/counsellor/search_group/",
    getAddStudentGroup: "/api/v2/counsellor/add_student_into_group/",
    getRemoveStudentGroup:"/api/v2/counsellor/remove_student_from_group/",
    sendGroupMessage: "/api/v2/counsellor/message_group/",
    getDeleteGroupMessage: "/api/v2/counsellor/message_group/",
    getDeleteGroupMessage1:"/api/v2/message-group/page/",
    selectedGroupListStudent:"/api/v2/counsellor/download_student_list/",
    groupListStudent:"/api/v2/counsellor/group_student_list/",
    override: "/api/v2/counsellor/simple_expression_override/",
    cannedMessage: "/api/v2/recruiter/message_template/",
    createCalendarEvent: "/api/v2/student/calendar/",
    deleteCalendarEvent: "/api/v2/student/calendar/",
    getCalendarEvent: "/api/v2/student/calendar/",
    restoreMessage: "/api/v2/counsellor/deleted_message/",
    unoverride: "/api/v2/counsellor/simple_expression_un_override/",
    message_group: "/api/v2/message-group",
    msg_group: "/api/v2/discussion/msg_group",
    status_calculation: "/api/v2/counsellor/status_calculation/",
  },
  superCounselor: {
    getStudentForCounselor: "/api/v2/counsellor/counsellor_dashboard/",
    getStudentStatisticsData: "/api/v2/counsellor/goal_statistics/",
    download_status: "/api/v2/counsellor/download_statistics/",
    getMyGroupList: "/api/v2/counsellor/group_student_list/",
  },
  schoolIT: {
    school_it_student_del:"/api/v2/counsellor/remove_student_from_group/",
    school_it_gr_delete: "/api/v2/counsellor/manage_group/",
    received_message: "/api/v2/comm/received_message/",
    goalPublish1:"/api/v2/coursepublish/schoolpublish",
    getStudent: "/api/v2/admin/user_operation/",
    getSchoolsList:"/api/v2/goal/student/get_college/",   
    studentCount: "/api/v2/admin/student_list/",
    studentModifyData: "/api/v2/admin/user_operation/",
    downloadStudent: "/api/v2/recruiter/search_user/",
    getMaintainGroup: "/api/v2/group",
    getMaintainCreateGroup: "/api/v2/counsellor/manage_group/",
    getMaintainGroupIT: "/api/v2/counsellor/manage_group/",
    getShowMaintain:"/api/v2/counsellor/group_student_list/",
    getStudentStatisticsData: "/api/v2/counsellor/goal_statistics/",
    status_calculation: "/api/v2/counsellor/status_calculation/",
    getStudentForMaintainGroup: "/api/v2/counsellor/counsellor_dashboard/",
    addStudentInGroup: "/api/v2/counsellor/add_student_into_group/",
    getMessageForSchoolIt: "/api/v2/comm/sender_count/",
    deleteStudentMessage: "/api/v2/counsellor/deleted_message/",
    getCounselor: "/api/v2/admin/user_operation/",
    getActivities: "/api/v2/admin/taglist/",
    saveStudent: "/api/v2/admin/user_operation/",
    goalPage: "/api/v2/goal/page",
    coursePage: "/api/v2/course/page",
    duplicateGoal: "/api/v2/goal/duplicate",
    goalrequirement: "/api/v2/goal/requirement",
    getSelectedCourse: "/api/v2/course",
    getGoalDetail: "/api/v2/goal",
    deleteGoalDetail: "/api/v2/goal/goal-delete",
    listOfCourse: "/api/v2/coursepublish",
    fieldOperators: "/api/v2/field_operators",
    dataSrcPage: "/api/v2/datasrc/page",
    DataSrc: "/api/v2/datasrc",
    runStatusDataSrc: "/api/v2/datasrc/runstatus",
    uploadStudentData: "/api/v2/datasrc/upload-studentdata ",
    uploadDataHistory: "/api/v2/ds_history/pag",
    deleteHistoryItem: "/api/v2/ds_history/del_upload",
    mapCollegeConfigurelist: "/api/v2/configure/maplist",
    goalFieldTypes: "/api/v2/goal_field_types",
    dataSourceCol: "/api/v2/datasrc_col",
    arithemetic: "/api/v2/arithmetic_operators",
    fieldTypes: "/api/v2/fieldtypes",
    goalFieldForCreateGoal: "/api/v2/goalfield",
    goalFieldForSaveGoal: "/api/v2/goalfieldmap/save",
    goalFieldForRemoveGoal:"/api/v2/goalfieldmap/check",
    uploadFile: "/api/v2/course/upload-file",
    uploadFileAfter: "/api/v2/course/upload-courselist",
    saveMapSchoolCollege: "/api/v2/collegecriteriamap/save",
    saveMapForModalOptions: "/api/v2/collegemapexpression/save",
    mapList: "/api/v2/goalfield/maplist",
    coursePublish: "/api/v2/coursepublish/schoolpublish",
    goalPublish: "/api/v2/goalpublish/schoolpublish",
    calculateGoalPublish: "/api/v2/goalpublish/calculate",
    updateDefaultGoal: "/api/v2/goal/def",
    createGoalMap: "/api/v2/goal/create",
    mapSchoolGoalSave: "/api/v2/goalfieldmap/save",
    courseMap: "/api/v2/course_map",
    uploadFileForCSVImport: "/api/v2/admin/upload-csv",
    duplicateCourse: "/api/v2/course/duplicate",
    listUpdateMapCourse: "/api/v2/course_list/list-update",
    mergeStudent: "/api/v2/admin/merge",
    getDistrictsList:"/api/v2/me/district",
    getDataSwal: "/api/v2/setup/school-setup-process"
  },
  admin: {
    queryShowData: "/api/v2/admin/configuration_list/",
    createSchoolIT: "/api/v2/admin/user_operation/",
    updateSchoolIT: "/api/v2/admin/user_operation/",
    deleteSchoolIT: "/api/v2/admin/user_operation/",
    getSchoolsList: "/api/v2/goal/student/get_college/",
    getRefClassesList: "/api/v2/goal/student/course_list/",
    getAllSchoolsList: "/api/v2/goal/student/get_college/",
    getCollegesList: "/api/v2/admin/college_view/",
    getDistrictsList: "/api/v2/admin/district/",
    getStudentStatistics: "/api/v2/admin/admin_statistics/",
    getStudentStatisticsExcel: "/api/v2/admin/download_statistics/",
    GET_CANNED_PAGE: "/api/v2/recruiter/message_template/",
    getCannedMessage: "/api/v2/recruiter/message_template/",
    adminPage: "/api/v2/admin/user_operation/",
    cannedMessage: "/api/v2/recruiter/message_template/",
    counselorAdmin: "/api/v2/admin/user_operation/",
    getCounselorsList: "/api/v2/admin/user_operation/",
    searchStudent: "/api/v2/admin/user_operation/",
    sendEmail: "/api/v2/admin/send-single-invite",
    getCSVData: "/api/v2/admin/student_list/",
    translateText: "/api/v2/translate/translate",
    sendInvites: "/api/v2/admin/send_invites/",
    goalBySchool: "/api/v2/studentgoal/goals_by_school",
    goalPublish: "/api/v2/goalpublish",
    updateSchoolGoal: "/api/v2/studentgoal/update_school_goal",
    withoutCounselor: "/api/v2/admin/without_counselor/",
    districtPage: "/api/v2/admin/district_school_pagination",
    districtSftpPage: "/api/v2/admin/sftp_status/",
    createSchool: "/api/v2/goal/student/get_college/",
    getSelectedCourse: "/api/v2/course",
    // getSchoolsList: "/api/v1/admin/school",
    // getCounselorsList: "/api/v1/admin/user",
    // getCollegesList: "/api/v1/admin/college",
    // getDistrictsList: "/api/v1/admin/district",
    // getStudentStatistics: "/api/v1/statistics/all",
    // searchStudent: "/api/v1/admin/page",
    // sendEmail: "/api/v1/admin/send-single-invite",
    // getCSVData: "/api/v1/admin/users-to-invite",
    getDeleteSchool: "/api/v2/goal/student/get_college/",
    getUpdateSchool: "/api/v2/goal/student/get_college/",
    getRecruiter: "/api/v2/admin/user_operation/",
    getExportRecruiter: "/api/v2/admin/export_result_report/",
    recruiterAdmin: "/api/v2/admin/user",
    subscribeContract: "/api/v2/admin/change_signed_status",
    updateRecruiter: "/api/v2/admin/user_operation/",
    districtPage: "/api/v2/admin/contract_setup/",
    subscribeContract: "/api/v2/admin/contract_setup/",
    getGoalDetail: "/api/v2/admin/goal_view/",
    createRecruiter: "/api/v2/admin/user_operation/",
    getAdminSchoolIT: "/api/v2/admin/user_operation/",
    goalAdminPage: "/api/v2/goal/page",
    coursePage: "/api/v2/course/page",
    goalAdminPublish: "/api/v2/goalpublish/publish",
    courseAdminPublish: "/api/v2/coursepublish/publish",
    duplicateAdminGoal: "/api/v2/goal/duplicate",
    goalField: "/api/v2/goalfield",
    listOfCourse: "/api/v2/course",
    getCourse: "/api/v2/course",
    goalRequiredField: "/api/v2/goalfield",
    goalFieldData: "/api/v2/admin/goal_field_types/",
    saveType: "/api/v2/admin/goal_field_types/",
    goalExpressionData: "/api/v2/admin/expression_types/",
    saveExpression:"/api/v2/admin/expression_types/",
    operatorShowData: "/api/v2/admin/field_operators/",
    saveOperator: "/api/v2/admin/field_operators/",
    arithemeticShowData: "/api/v2/admin/arithmatic_operators/",
    saveArthOptr: "/api/v2/admin/arithmatic_operators/",
    typeShowData: "/api/v2/admin/field_types/",
    saveField: "/api/v2/admin/field_types/",
    queryCriteriasShowData: "/api/v2/admin/configuration_list/",
    saveCriteria: "/api/v2/admin/configuration_list/",
    getCourseList: "/api/v2/goal",
    saveCourse:"/api/v2/configure/type"
  }
};
