import React, { Fragment, useState, useEffect, useRef } from "react";
import GetStudentService from "../../../../services/SchoolIT/GetStudentService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalBody } from "reactstrap";
import {
  faCheck,
  faTimes,
  faSearch,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import AppHeader from "../../../../Layout/AppHeader";
import AppSidebar from "../../../../Layout/AppSidebar";
import moment from "moment";

const API_URL = process.env.REACT_APP_API_URL;

const RunStatusModal = ({ isOpen, setIsOpen, runStatus }) => {
  return (
    <Fragment>
       <AppHeader />
      <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
      <Modal isOpen={isOpen} centered={true}>
        <ModalBody>
          <div
            style={{ textAlign: "center", margin: "10px", fontSize: "20px" }}
          >
            <p>Would you like to run the status calculation?</p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "10px",
              }}
            >
              <button
                onClick={runStatus}
                style={{
                  backgroundColor: "#009900",
                  color: "white",
                  padding: "5px",
                  border: "none",
                  outline: "none",
                  marginRight: "20px",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon
                  style={{ margin: "0 5px" }}
                  color="white"
                  icon={faCheck}
                />
                Ok
              </button>
              <button
                onClick={() => setIsOpen(false)}
                style={{
                  backgroundColor: "#efefef",
                  color: "black",
                  padding: "5px",
                  border: "none",
                  outline: "none",
                  marginLeft: "20px",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon style={{ margin: "0 5px" }} icon={faTimes} />
                Cancel
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      </div>
      </div>
    </Fragment>
  );
};

const CourseSetModal = ({ isOpen, closeHandler, uploadStudentData }) => {
  const [input, setInput] = useState({ period: 0, periodsPerYear: 2 });

  return (
    <Fragment>
       <AppHeader />
      <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
      <Modal isOpen={isOpen} toggle={closeHandler} centered>
        <ModalBody>
          <h5 style={{ padding: "15px 16px 0 16px", textAlign: "center" }}>
            Required Course Set Input
          </h5>
          <div
            style={{
              position: "relative",
              padding: "10px",
              margin: "10px 0 10px 50px",
              fontSize: "20px",
            }}
          >
            <label>Period : </label>&nbsp;
            <input
              value={input.period}
              onChange={(e) => setInput({ ...input, period: e.target.value })}
              style={{ width: "70%", padding: "5px", borderRadius: "5px" }}
              type="number"
              className="require-input-name"
            />
            <br />
            <br />
            <label>Periods Per Year : </label>&nbsp;
            <input
              value={input.periodsPerYear}
              onChange={(e) =>
                setInput({ ...input, periodsPerYear: e.target.value })
              }
              style={{ width: "50%", padding: "5px", borderRadius: "5px" }}
              type="number"
              className="require-input-name"
            />
          </div>
        </ModalBody>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "5px 0 30px 0",
          }}
        >
          <button
            disabled={!input}
            style={{ padding: "5px 20px", margin: "0 30px", fontSize: "20px" }}
            className={"save-modal-button"}
            onClick={() => uploadStudentData(input)}
          >
            <FontAwesomeIcon icon={faSave} style={{ margin: "0 10px" }} />
            Save
          </button>
        </div>
      </Modal>
      </div>
      </div>
    </Fragment>
  );
};
const UploadDataSource = () => {
  let history = useHistory();
  const [user, setUser] = useState(
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  );
  const [searchedDataSource, setSearchedDataSource] = useState();
  const [dataSource, setDataSource] = useState();
  const [page, setPage] = useState(1);
  const [uploadHistoryData, setUploadHistoryData] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [selectedDataSourceName, setSelectedDataSourceName] = useState();
  const [selectedDataSource, setSelectedDataSource] = useState();
  const [showError, setShowError] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [openRunStatusModal, setOpenRunStatusModal] = useState(false);
  const [openCourseSetModal, setOpenCourseSetModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
  const uploadFileRef = useRef();

  function filterByValue(array, string) {
    let filteredArray = [];
    array.forEach((element) => {
      if (element["name"].toLowerCase().includes(string)) {
        filteredArray.push(element);
      }
    });
    return filteredArray;
  }

  useEffect(() => {
    if (searchedDataSource && uploadHistoryData && uploadHistoryData?.upload) {
      let value = filterByValue(uploadHistoryData?.upload, searchedDataSource);
      setTableData(value);
      console.log(value);
    } else if (
      uploadHistoryData &&
      uploadHistoryData?.upload &&
      (!searchedDataSource || searchedDataSource.length === 0)
    ) {
      setTableData(uploadHistoryData?.upload);
      console.log(uploadHistoryData?.upload);
    }
  }, [searchedDataSource]);

  const getDataSource = () => {
    GetStudentService.getDataSrc(user?.school_id)
      .then((data) => {
        console.log("data from the upload service", data);
        setDataSource(data?.data?.objects);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const dataSourceHandler = (e) => {
    setSelectedDataSourceName(e.target.value);
  };

  useEffect(() => {
    if (selectedDataSourceName) {
      let filteredValue = dataSource?.filter(
        (item) => item.name === selectedDataSourceName
      );
      console.log("filteredvalue", filteredValue);
      setSelectedDataSource(filteredValue[0]);
    }
  }, [selectedDataSourceName]);

  const getDataUploadHistory = () => {
    let obj = {
      page: page,
      school_id: user?.school_id,
    };
    GetStudentService.getDataUploadHistory(obj)
      .then((data) => {
        console.log("data from the service", data);
        setUploadHistoryData(data?.data);
        setTableData(data?.data?.upload);
        console.log(data?.data);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const deleteHistoryItem = () => {
    let obj = {
      datasource_id: tableData.datasource_id,
      upload_id: tableData.id,
    };
    console.log(obj);
    GetStudentService.deleteHistoryItem(obj)
      .then((data) => {
        console.log("data from the service", data);
        setOpenRunStatusModal(true);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const runStatusDataSrc = () => {
    setOpenRunStatusModal(false);
    let obj1 = { school_id: user?.school_id };
    GetStudentService.runStatusDataSrc(obj1)
      .then((data) => {
        console.log("data from the service", data);
        history.push("/school_it/ccplus");
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const uploadStudentData = (input) => {
    let obj = { ...input, school_id: user?.school_id , upload: selectedFile };
    setOpenCourseSetModal(false);
    GetStudentService.uploadStudentData(obj)
      .then((data) => {
        console.log("data from the service", data);
        setOpenRunStatusModal(true);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const uploadFileHandler = () => {
    setSelectedFile(uploadFileRef.current.files[0]);
    let formData = new FormData();
    formData.append("csvfilename", undefined);
    formData.append("datasource_id", selectedDataSource?.id);
    formData.append("school_id", user?.school_id);
    formData.append("csv_file", uploadFileRef.current.files[0]);
    fetch(`${API_URL}/api/v2/datasrc/validate-ds`, {
      headers: {
        "x-authorization": `Bearer ${localStorage.getItem("token")}`,
      },
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data from the validate service", data);
        if (data?.valid === "not_valid") {
          setShowError(true);
          setShowSuccess(false);
          // setUploadButtonDisabled(true);
          setErrorMsg(
            " Spreadsheet columns does not match with selected Datasource. Please upload a valid datasource file, to proceed to save"
          );
        } else if (data?.valid === "valid") {
          setShowSuccess(true);
          setShowError(false);
          setUploadButtonDisabled(false);
          setSuccessMsg(
            "You have uploaded a valid datasource, please do proceed to save the data"
          );
        } else if (data?.valid === "datatype") {
          setShowError(true);
          setShowSuccess(false);
          // setUploadButtonDisabled(true);
          setErrorMsg(
            "Datatype of values imported should match Datatype definition of the column in Goalfield Map!"
          );
        } else if (data?.valid === "map") {
          setShowError(true);
          setShowSuccess(false);
          // setUploadButtonDisabled(true);
          setErrorMsg(
            "The provided Datasource has not been mapped for any goal / course data"
          );
        }
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
    // setUploadButtonDisabled(false);
  };

  console.log("selectedDataSource", selectedDataSource);

  useEffect(() => {
    getDataSource();
    getDataUploadHistory();
  }, []);

  return (
    <Fragment>
       <AppHeader />
      <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
      <div style={{ padding: "15px" }}>
        <div className="flex" style={{ justifyContent: "space-between" }}>
          <p style={{ fontSize: "24px" }} className="no-margin-bottom">
            Upload Data Source
          </p>
          <div>
            <button
              onClick={() => history.push("/school_it/create_goal")}
              className="create-maintain-group-button"
            >
              New Goal
            </button>
            <button
              onClick={() => history.push("/school_it/course")}
              className="create-maintain-group-button"
            >
              New Required Course Set
            </button>
            <button
              onClick={() => history.push("/school_it/ccplus")}
              className="create-maintain-group-button"
            >
              Back
            </button>
          </div>
        </div>
        <div
          style={{ width: "70%", border: "1px solid black", padding: "30px" }}
        >
          <div>
            <span
              className="bold"
              style={{ fontSize: "14px", marginRight: "30px" }}
            >
              Upload SpreadSheet for the Data Source
            </span>
            <select
              style={{ width: "300px", padding: "5px 10px" }}
              onChange={(e) => dataSourceHandler(e)}
              value={selectedDataSourceName}
            >
              <option selected disabled value={null}>
                ----DataSource Name----
              </option>
              {dataSource?.map((item, index) => (
                <option value={item?.name}>{item?.name}</option>
              ))}
            </select>
          </div>
          <br />
          <input
            type="file"
            accept="text/csv"
            onChange={uploadFileHandler}
            ref={uploadFileRef}
            disabled={!selectedDataSource}
          />

          {showError && (
            <div className="error-class">
              <p className="no-margin-bottom">{errorMsg}</p>
            </div>
          )}
          {showSuccess && (
            <div className="success-class">
              <p className="no-margin-bottom">{successMsg}</p>
            </div>
          )}
          <br />
          <br />
          <button
            // disabled={uploadButtonDisabled}
            onClick={() => setOpenCourseSetModal(true)}
            className={
              uploadButtonDisabled
                ? "disabled-modal-button"
                : "save-modal-button"
            }
          >
            Upload
          </button>
        </div>
        <div style={{ margin: "20px 0px" }}>
          <p style={{ fontSize: "24px" }}>History of Upload</p>
          <div className="flex">
            <div
              style={{
                background: "#eeeeee",
                padding: "2px 10px",
                border: "1px solid black",
                borderRadius: "5px",
              }}
            >
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input
              type="text"
              style={{ width: "67%" }}
              placeholder="Search Data Source"
              onChange={(e) =>
                setSearchedDataSource(e.target.value.toLowerCase())
              }
            />
          </div>
        </div>
        <div>
          <table className="uploadhistoryTable">
            <tr>
              <th style={{ border: "1px solid #ddd" }}>Data Source Name</th>
              <th style={{ border: "1px solid #ddd" }}>Date Uploaded</th>
              <th style={{ border: "1px solid #ddd" }}>Status</th>
              <th style={{ border: "1px solid #ddd" }}>Active</th>
              <th style={{ border: "1px solid #ddd" }}>Action</th>
            </tr>
            {tableData.map((item, index) => (
              <tr>
                <td>{item?.name}</td>
                <td>
                  {moment(item?.date_created).format("ddd MMM Do YYYY, h:mm a")}
                </td>
                <td>{item?.status}</td>
                <td>
                  {item.active === true ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "#009900" }}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                  )}
                </td>
                <td>
                  <button className="cancel-button" onClick={deleteHistoryItem}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </td>
              </tr>
            ))}
          </table>
        </div>

        {openRunStatusModal && (
          <RunStatusModal
            isOpen={openRunStatusModal}
            setIsOpen={setOpenRunStatusModal}
            runStatus={runStatusDataSrc}
          />
        )}

        {openCourseSetModal && (
          <CourseSetModal
            isOpen={openCourseSetModal}
            closeHandler={() => {
              setOpenCourseSetModal(false);
            }}
            uploadStudentData={uploadStudentData}
          />
        )}
      </div>
      </div>
      </div>
    </Fragment>
  );
};
export default UploadDataSource;
