import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  faTimes,
  faAsterisk,
  faSave,
  faTrash,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gradeData1 } from "../../../constants/data";
import GetStudentService from "../../../services/SchoolIT/GetStudentService";
import swal from "sweetalert";
import { Modal, ModalBody } from "reactstrap";

const StudentModal = ({ isOpen, setIsOpen, data }) => {
  return (
    <Fragment>
      <Modal isOpen={isOpen} centered={true}>
        <ModalBody>
          <div style={{ textAlign: "center" }}>
            <p>{data.message}</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                onClick={data.function1}
                style={{
                  backgroundColor: "#009900",
                  color: "white",
                  padding: "5px",
                  border: "none",
                  outline: "none",
                  marginRight: "20px",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon
                  style={{ margin: "0 5px" }}
                  color="white"
                  icon={faCheck}
                />
                Ok
              </button>
              <button
                onClick={() => setIsOpen(false)}
                style={{
                  backgroundColor: "#efefef",
                  color: "black",
                  padding: "5px",
                  border: "none",
                  outline: "none",
                  marginLeft: "20px",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon style={{ margin: "0 5px" }} icon={faTimes} />
                Cancel
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

const CreateStudent = ({
  setOpenCreateStudent,
  modify,
  school_id,
  getStudentwithId,
  getCounselor,
}) => {
  const counselorRef = useRef();
  const timeoutRef = useRef();
  const activitiesRef = useRef();
  const athleticsRef = useRef();
  const timeout = useRef();
  const [grade, setGrade] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [showCounselorContainer, setShowCounselorContainer] = useState(false);
  const [showActivitiesContainer, setShowAtivitiesContainer] = useState(false);
  const [activitiesList, setActivitiesList] = useState();
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [showAthleticsContainer, setShowAthleticsContainer] = useState(false);
  const [athleticsList, setAthleticsList] = useState();
  const [selectedAthletics, setSelectedAthletics] = useState([]);
  const [counselorList, setCounselorList] = useState([]);
  const [counselorName, setCounselorName] = useState("");
  const [selectedCounselor, setSelectedCounselor] = useState();
  const [email, setEmail] = useState();
  const [parentEmail, setParentEmail] = useState();
  const [recoveryEmail, setRecoveryEmail] = useState();
  const [mobile, setMobile] = useState();
  const [parentMobile, setParentMobile] = useState();
  const [parentName, setParentName] = useState();
  const [activities, setActivities] = useState();
  const [athletics, setAthletics] = useState();
  const [firstInFamily, setFirstInFamily] = useState(false);
  const [agreeToshare, setAgreeToshare] = useState(true);
  const [showPasswordError, setShowPasswordError] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openMergeModal, setOpenMergeModal] = useState(false);
  const [searchMergeStudent, setSearchMergeStudent] = useState();
  const [mergeStudentId, setMergeStudentId] = useState();
  const [mergeStudentList, setMergeStudentList] = useState();

  const modifyData = () => {
    setSearchMergeStudent("");
    setFirstName(modify?.student?.first_name);
    setLastName(modify?.student?.last_name);
    setGrade(modify?.student?.current_grade);
    setEmail(modify?.student?.email);
    setMobile(modify?.student?.mobile);
    setParentEmail(modify?.student?.parent_email);
    setParentName(modify?.student?.parent_name);
    setParentMobile(modify?.student?.parent_mobile);
    setRecoveryEmail(modify?.student?.backup_email);
    if (modify?.counselor) setSelectedCounselor(modify?.counselor);
    else setSelectedCounselor();
    setFirstInFamily(modify?.student?.first_generation);
    setAgreeToshare(modify?.student?.student_opt_out);
    if (modify?.selectedactivities_obj) {
      let arr = modify?.selectedactivities_obj.map((item) => {
        delete item?.id;
        return item;
      });
      setSelectedActivities(arr);
    } else setSelectedActivities([]);
    if (modify?.selectedathletics) {
      let arr = modify?.selectedathletics.map((item) => {
        delete item?.id;
        return item;
      });
      setSelectedAthletics(arr);
    } else setSelectedAthletics([]);
  };

  useEffect(() => {
    if (modify) {
      modifyData();
    }
  }, [modify]);

  const getObject = () => {
    // let activitiesId = selectedActivities?.map((item) => item.id);
    // let athleticsId = selectedAthletics?.map((item) => item.id);
    let obj = {
      student_opt_out: agreeToshare,
      selectedactivities_obj: selectedActivities,
      // selectedactivities: activitiesId,
      selectedathletics: selectedAthletics,
      // selectedathletics: selectedAthletics,
      counselor: selectedCounselor?.id || null,
      current_grade: grade,
      first_name: firstName,
      last_name: lastName,
      email: email,
      parent_email: parentEmail,
      backup_email: recoveryEmail,
      mobile: mobile,
      parent_mobile: parentMobile,
      parent_name: parentName,
      first_generation: firstInFamily,
      user_id: modify?.student?.id,
      type: "student",
    };
    return obj;
  };

  const setNull = () => {
    setSelectedAthletics([]);
    setSelectedActivities([]);
    setSelectedCounselor();
    setGrade("");
    setFirstInFamily(false);
    setAgreeToshare(true);
    setCounselorName("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setRecoveryEmail("");
    setParentEmail("");
    setMobile("");
    setParentMobile("");
    setPassword("");
    setConfirmPassword("");
    setParentName("");
  };

  const saveButtonHandler = () => {
    let obj = getObject();
    obj.password = password;
    obj.password_confirm = confirmPassword;
    obj.school = school_id;
    GetStudentService.saveStudent(obj)
      .then((data) => {
        console.log("data from the service", data);
        setNull();
        getStudentwithId("all");
        setOpenCreateStudent(false);
        swal({
          text: data?.data?.message,
          icon: "success",
          buttons: {
            ok: true,
          },
          closeOnClickOutside: true,
        });
      })
      .catch((error) => {
        console.log("error from the service", error, error?.response);
        swal({
          text: error?.response?.data?.message,
          icon: "warning",
          dangerMode: true,
          buttons: {
            cancel: true,
            confirm: true,
          },
          closeOnClickOutside: true,
        }).then((value) => {
          console.log("value", value);
        });
      });
  };

  const updateButtonHandler = () => {
    let obj = getObject();
    if (password) obj.password = password;
    GetStudentService.updateStudent(obj)
      .then((data) => {
        console.log("student from the service", data);
        setNull();
        getStudentwithId("all");
        setOpenCreateStudent(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const getCounselorName = (value) => {
    GetStudentService.getCounselorName(value, "counselor", school_id)
      .then((data) => {
        console.log("data from the service", data?.data);
        setCounselorList(data?.data?.result);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const getStudentfromSearchHandler = (q) => {
    let obj = {
      q: q,
      role: "student",
      school: school_id,
    };
    GetStudentService.searchStudent(obj)
      .then((data) => {
        console.log("student from the service", data);
        setMergeStudentList(data.data.result);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const searchHandler = (e) => {
    let q = e.target.value;
    setSearchMergeStudent(q);
    if (q === "") {
      setMergeStudentList();
    } else {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        getStudentfromSearchHandler(q);
      }, 500);
    }
  };

  const mergeStudent = () => {
    let obj = {
      student_with_id: mergeStudentId,
      student_without_id: modify?.student?.id,
    };
    GetStudentService.mergeStudent(obj)
      .then((data) => {
        // console.log("student from the service", data)
        swal({
          text: data?.data?.message,
          icon: "warning",
          dangerMode: true,
          buttons: {
            cancel: true,
          },
          closeOnClickOutside: true,
        });
        setOpenMergeModal(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setOpenMergeModal(false);
      });
  };

  const deleteStudent = () => {
    GetStudentService.deleteStudent(modify?.student?.id)
      .then((data) => {
        console.log("student from the service", data);
        swal({
          text: "Student deleted successfully!",
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: true,
        }).then((value) => {
          if (value === "OK") {
            window.location.reload();
          }
        });
        setNull();
        getStudentwithId("all");
        setOpenCreateStudent(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
    setOpenDeleteModal(false);
  };

  const counselorHandler = () => {
    clearTimeout(timeoutRef.current);
    if (!counselorName) {
      setShowCounselorContainer(false);
      setCounselorList([]);
    }
    setCounselorName(counselorRef.current.value);
    timeoutRef.current = setTimeout(() => {
      getCounselorName(counselorRef.current.value);
    }, 1000);
    setShowCounselorContainer(true);
  };

  const removeCounselorName = (value) => {
    // let filteredValue = selectedCounselor?.filter((item) => item !== value);
    // setSelectedCounselor(filteredValue);
    setSelectedCounselor();
  };

  const handleCounselorSelect = (item) => {
    setCounselorName("");
    // setSelectedCounselor([...selectedCounselor, item]);
    setSelectedCounselor(item);
    setCounselorList([]);
    setShowCounselorContainer(false);
  };

  const passwordValidation = (value) => {
    if (password.length < 8) {
      setShowPasswordError("Password must be greater than 8 characters");
    } else if (password.length >= 8) {
      setShowPasswordError(false);
    }
  };

  const confirmPasswordValidation = (value) => {
    if (value !== password)
      setShowPasswordError("Password and Confirm Password should be same.");
    else setShowPasswordError(false);
  };

  const handleActivitiesChange = () => {
    clearTimeout(timeoutRef.current);
    setActivities(activitiesRef.current.value);
    if (!activitiesRef.current.value) {
      setShowAtivitiesContainer(false);
      setActivitiesList([]);
    } else {
      timeoutRef.current = setTimeout(() => {
        getActivities(activitiesRef.current.value);
      }, 1000);
    }
    setShowAtivitiesContainer(true);
  };

  const handleAthleticsChange = () => {
    clearTimeout(timeoutRef.current);
    setAthletics(athleticsRef.current.value);
    if (!athleticsRef.current.value) {
      setShowAthleticsContainer(false);
      setAthleticsList([]);
    } else {
      timeoutRef.current = setTimeout(() => {
        getAthletics(athleticsRef.current.value);
      }, 1000);
    }
    setShowAthleticsContainer(true);
  };

  const getActivities = (value) => {
    let selected = "";
    selectedActivities.map((item) => (selected += "act=" + item.id + "&"));
    GetStudentService.getActivitiesList(value, selected)
      .then((data) => {
        console.log("data from the service", data);
        setActivitiesList(data?.data?.objects);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const getAthletics = (value) => {
    let selected = "";
    selectedAthletics.map((item) => (selected += "ath=" + item.id + "&"));
    GetStudentService.getAthleticsList(value, selected)
      .then((data) => {
        console.log("data from the service", data);
        setAthleticsList(data?.data?.objects);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const handleSelectedActivities = (item) => {
    setActivitiesList([]);
    setShowAtivitiesContainer(false);
    activitiesRef.current.value = null;
    setActivities();
    setSelectedActivities([...selectedActivities, item]);
  };

  const handleSelectedAthletics = (item) => {
    setAthleticsList([]);
    setShowAthleticsContainer(false);
    athleticsRef.current.value = null;
    setAthletics();
    setSelectedAthletics([...selectedAthletics, item]);
  };

  const removeSelectedActivities = (value) => {
    let filteredValue = selectedActivities?.filter((item) => item !== value);
    setSelectedActivities(filteredValue);
  };

  const removeSelectedAthletics = (value) => {
    let filteredValue = selectedAthletics?.filter((item) => item !== value);
    setSelectedAthletics(filteredValue);
  };

  return (
    <Fragment>
      <div className="create-student-container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ fontSize: "18px" }}>
            {modify ? "Modify Student" : "New Student"}
          </p>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ cursor: "pointer", fontSize: "18px" }}
            onClick={() => {
              setNull();
              setOpenCreateStudent(false);
            }}
          />
        </div>
        <div style={{ padding: "0 30px" }}>
          <div
            className="flex"
            style={{ marginTop: "10px", justifyContent: "space-between" }}
          >
            <p
              style={{ width: "20%", fontSize: "14px" }}
              className="bold no-margin-bottom"
            >
              Counselor
            </p>
            <input
              type="text"
              style={{ width: "75%" }}
              placeholder="Type the counselor name"
              ref={counselorRef}
              onChange={counselorHandler}
              value={counselorName}
            />
          </div>

          {showCounselorContainer && (
            <ul className="maintain-group-student-dropdown-menu">
              {showCounselorContainer &&
                counselorList?.length > 0 &&
                counselorList.map((item, index) => (
                  <li
                    onClick={() => handleCounselorSelect(item)}
                    key={index}
                    className="flex maintain-group-student-list cursor"
                  >
                    <span>{item?.name}</span>
                  </li>
                ))}
            </ul>
          )}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              width: "100%",
              marginTop: "5px",
            }}
          >
            {selectedCounselor && (
              <div
                style={{
                  background: "#009900",
                  color: "white",
                  padding: "3px 5px",
                  cursor: "pointer",
                  fontSize: "14px",
                  margin: "0px 2px",
                }}
              >
                {selectedCounselor?.name}{" "}
                <FontAwesomeIcon
                  icon={faTimes}
                  color="white"
                  style={{ marginLeft: "5px" }}
                  onClick={removeCounselorName}
                />
              </div>
            )}
          </div>
          <div
            className="flex"
            style={{
              marginTop: "15px",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <p
              style={{ width: "20%", fontSize: "14px" }}
              className="bold no-margin-bottom"
            >
              Grade
            </p>
            <select
              value={grade}
              onChange={(e) => setGrade(e.target.value)}
              style={{ width: "75%", height: "30px" }}
            >
              <option disabled selected value="">
                Select Current Grade
              </option>
              {gradeData1.map((item, index) => (
                <option
                  style={{ textTransform: "capitalize" }}
                  value={item?.value}
                >
                  {item?.name}
                </option>
              ))}
            </select>
            <FontAwesomeIcon
              style={{ fontSize: "12px", position: "absolute", right: "5px" }}
              icon={faAsterisk}
              color="red"
            />
          </div>
        </div>
        <div style={{ marginTop: "30px" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "400",
              borderBottom: "1px solid #e5e5e5",
            }}
          >
            Personal information
          </p>
          <div style={{ padding: "0 30px" }}>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                First Name
              </p>
              <input
                type="text"
                style={{ width: "75%" }}
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <FontAwesomeIcon
                style={{ fontSize: "12px", position: "absolute", right: "5px" }}
                icon={faAsterisk}
                color="red"
              />
            </div>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                Last Name
              </p>
              <input
                type="text"
                style={{ width: "75%" }}
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <FontAwesomeIcon
                style={{ fontSize: "12px", position: "absolute", right: "5px" }}
                icon={faAsterisk}
                color="red"
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: "30px" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "400",
              borderBottom: "1px solid #e5e5e5",
            }}
          >
            Contact
          </p>
          <div style={{ padding: "0 30px" }}>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                Email
              </p>
              <input
                type="email"
                style={{ width: "75%" }}
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
              />
              <FontAwesomeIcon
                style={{ fontSize: "12px", position: "absolute", right: "5px" }}
                icon={faAsterisk}
                color="red"
              />
            </div>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                Parent Email
              </p>
              <div style={{ width: "75%" }}>
                <input
                  type="email"
                  style={{ width: "100%" }}
                  placeholder="Parent Email"
                  value={parentEmail}
                  onChange={(e) => setParentEmail(e.target.value.toLowerCase())}
                />
                <p className="help-block">
                  Email address for daily/weekly activity log to be sent
                </p>
              </div>
            </div>

            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                Recovery Email
              </p>
              <div style={{ width: "75%" }}>
                <input
                  type="email"
                  style={{ width: "100%" }}
                  placeholder="Recovery Email"
                  value={recoveryEmail}
                  onChange={(e) =>
                    setRecoveryEmail(e.target.value.toLowerCase())
                  }
                />
                <p className="help-block">
                  Used in case account needs to be recovered
                </p>
              </div>
            </div>

            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                Mobile
              </p>
              <div style={{ width: "75%" }}>
                <input
                  type="text"
                  style={{ width: "100%" }}
                  placeholder="+19999999999"
                  onChange={(e) => setMobile(e.target.value)}
                  value={mobile}
                />
                <p className="help-block">Hint: +13238511115</p>
              </div>
            </div>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                Parent Mobile
              </p>
              <div style={{ width: "75%" }}>
                <input
                  type="text"
                  style={{ width: "100%" }}
                  placeholder="+19999999999"
                  value={parentMobile}
                  onChange={(e) => setParentMobile(e.target.value)}
                />
                <p className="help-block">Hint: +13238511115</p>
              </div>
            </div>
            <div
              className="flex"
              style={{ marginTop: "15px", justifyContent: "space-between" }}
            >
              <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                Parent Name
              </p>
              <input
                type="text"
                style={{ width: "75%" }}
                placeholder="Parent Name"
                value={parentName}
                onChange={(e) => setParentName(e.target.value)}
              />
            </div>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                Activities
              </p>
              <input
                type="text"
                style={{ width: "75%" }}
                placeholder="Type a activities name to add"
                onChange={handleActivitiesChange}
                ref={activitiesRef}
                value={activities}
                autoComplete="off"
              />

              {showActivitiesContainer && (
                <div style={{ width: "75%", marginLeft: "25%" }}>
                  <ul className="maintain-group-student-dropdown-menu">
                    {activitiesList?.length > 0 &&
                      activitiesList.map((item, index) => (
                        <li
                          onClick={() => handleSelectedActivities(item)}
                          key={index}
                          className="flex maintain-group-student-list cursor"
                        >
                          <span>{item.name}</span>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "5px",
                }}
              >
                {selectedActivities &&
                  selectedActivities.length > 0 &&
                  selectedActivities?.map((item) => (
                    <div
                      style={{
                        background: "#009900",
                        color: "white",
                        padding: "3px 5px",
                        cursor: "pointer",
                        fontSize: "14px",
                        margin: "0px 2px",
                      }}
                    >
                      {item?.name || item.activities_name}
                      <FontAwesomeIcon
                        icon={faTimes}
                        color="white"
                        style={{ marginLeft: "5px" }}
                        onClick={() => removeSelectedActivities(item)}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div
              className="flex"
              style={{ marginTop: "15px", justifyContent: "space-between" }}
            >
              <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                Athletics
              </p>
              <input
                ref={athleticsRef}
                type="text"
                style={{ width: "75%" }}
                placeholder="Type a athletics name to add"
                value={athletics}
                onChange={handleAthleticsChange}
                autocomplete="off"
              />
              {showAthleticsContainer && (
                <div style={{ width: "75%", marginLeft: "25%" }}>
                  <ul className="maintain-group-student-dropdown-menu">
                    {athleticsList?.length > 0 &&
                      athleticsList.map((item, index) => (
                        <li
                          onClick={() => handleSelectedAthletics(item)}
                          key={index}
                          className="flex maintain-group-student-list cursor"
                        >
                          <span>{item.name}</span>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "5px",
                }}
              >
                {selectedAthletics &&
                  selectedAthletics.length > 0 &&
                  selectedAthletics?.map((item) => (
                    <div
                      style={{
                        background: "#009900",
                        color: "white",
                        padding: "3px 5px",
                        cursor: "pointer",
                        fontSize: "14px",
                        margin: "0px 2px",
                      }}
                    >
                      {item?.name || item.athletics_name}
                      <FontAwesomeIcon
                        icon={faTimes}
                        color="white"
                        style={{ marginLeft: "5px" }}
                        onClick={() => removeSelectedAthletics(item)}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {!getCounselor ? (
          <div>
            <input
              type="checkbox"
              style={{ marginRight: "5px" }}
              value={firstInFamily}
              onChange={() => setFirstInFamily(!firstInFamily)}
              checked={firstInFamily}
            />
            <span style={{ fontSize: "14px", color: "#737373" }}>
              Please Check the Box if you are the first in your family who wants
              to attend College.
            </span>
          </div>
        ) : (
          ""
        )}
        <div>
          <div style={{ marginTop: "30px" }}>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "400",
                borderBottom: "1px solid #e5e5e5",
              }}
            >
              User information
            </p>
          </div>
          <div style={{ padding: "0 30px" }}>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                Password
              </p>
              <input
                type="password"
                style={{ width: "75%" }}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={() => passwordValidation(password)}
              />
              <FontAwesomeIcon
                style={{ fontSize: "12px", position: "absolute", right: "5px" }}
                icon={faAsterisk}
                color="red"
              />
            </div>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                Confirm Password
              </p>
              <input
                type="password"
                style={{ width: "75%" }}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onBlur={() => confirmPasswordValidation(confirmPassword)}
              />
              <FontAwesomeIcon
                style={{ fontSize: "12px", position: "absolute", right: "5px" }}
                icon={faAsterisk}
                color="red"
              />
            </div>
          </div>
          {!getCounselor ? (
            <div
              style={{
                margin: "10px 0px",
                textAlign: "left",
              }}
            >
              <input
                type="checkbox"
                style={{ marginRight: "10px" }}
                value={agreeToshare}
                onChange={() => setAgreeToshare(!agreeToshare)}
                checked={agreeToshare}
              />
              <span style={{ fontSize: "14px", color: "#737373" }}>
                I would like to increase my chances of being recruited by a
                great university by agreeing to share my academic progress and
                goals information with them.
              </span>
            </div>
          ) : (
            ""
          )}
          {!getCounselor
            ? modify &&
              !modify?.student?.student_id && (
                <div style={{ padding: "0 30px" }}>
                  <div
                    className="flex"
                    style={{
                      marginTop: "  10px",
                      justifyContent: "space-between",
                      position: "relative",
                    }}
                  >
                    <p
                      style={{ width: "20%", fontSize: "14px" }}
                      className="bold no-margin-bottom"
                    >
                      Merge with Student
                    </p>
                    <input
                      type="text"
                      style={{ width: "55%" }}
                      placeholder="Name of the student to Merge With"
                      onChange={searchHandler}
                      value={searchMergeStudent}
                    />
                    <button
                      className="create-maintain-group-button"
                      style={{
                        width: "20%",
                        opacity: searchMergeStudent ? "1" : "0.6",
                      }}
                      disabled={!searchMergeStudent}
                      onClick={() => setOpenMergeModal(true)}
                    >
                      <FontAwesomeIcon
                        icon={faSave}
                        style={{
                          marginRight: "5px",
                          marginTop: "3px",
                        }}
                      />
                      Merge
                    </button>
                  </div>
                </div>
              )
            : ""}
          {mergeStudentList?.length > 0 && (
            <ul className="maintain-group-student-dropdown-menu">
              {mergeStudentList?.map((item, index) => (
                <li
                  onClick={() => {
                    setSearchMergeStudent(item.name);
                    setMergeStudentId(item.id);
                    setMergeStudentList();
                  }}
                  key={index}
                  className="flex maintain-group-student-list cursor"
                >
                  <span>
                    {item.name}&nbsp;{item.email}
                  </span>
                </li>
              ))}
            </ul>
          )}
          {!getCounselor
            ? modify &&
              !modify?.student?.student_id && (
                <p style={{ margin: "10px 0" }}>
                  This student account does not have a student ID associated
                  with it. Please merge it with a student that does have an ID.
                </p>
              )
            : ""}

          {showPasswordError && (
            <p style={{ color: "#a94442", margin: "10px 20px" }}>
              {showPasswordError}
            </p>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            gap: "20px",
            margin: "20px 20px 30px 0",
          }}
        >
          {modify && (
            <button
              className="cancel-modal-button"
              onClick={() => setOpenDeleteModal(true)}
            >
              <FontAwesomeIcon icon={faTrash} />
              &nbsp;Delete
            </button>
          )}
          {modify && (
            <button
              onClick={updateButtonHandler}
              // disabled={!password}
              className="save-modal-button"
            >
              <FontAwesomeIcon
                icon={faSave}
                style={{
                  marginRight: "5px",
                  marginTop: "3px",
                }}
              />
              Save
            </button>
          )}
          {!modify && (
            <button
              onClick={saveButtonHandler}
              disabled={
                !(
                  grade &&
                  email &&
                  firstName &&
                  lastName &&
                  password &&
                  confirmPassword &&
                  !showPasswordError
                )
              }
              className={
                !(
                  grade &&
                  firstName &&
                  email &&
                  lastName &&
                  password &&
                  confirmPassword &&
                  !showPasswordError
                )
                  ? "disabled-modal-button"
                  : "save-modal-button"
              }
            >
              <FontAwesomeIcon
                icon={faSave}
                style={{
                  marginRight: "5px",
                  marginTop: "3px",
                }}
              />
              Save
            </button>
          )}
        </div>
      </div>
      {openDeleteModal && (
        <StudentModal
          setIsOpen={setOpenDeleteModal}
          isOpen={openDeleteModal}
          data={{
            function1: deleteStudent,
            message: "Are you sure you want to delete?",
          }}
        />
      )}
      {openMergeModal && (
        <StudentModal
          setIsOpen={setOpenMergeModal}
          isOpen={openMergeModal}
          data={{
            function1: mergeStudent,
            message: "Are you sure you want to merge this student?",
          }}
        />
      )}
    </Fragment>
  );
};
export default CreateStudent;
