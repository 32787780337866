import React, { Component, Fragment, useEffect, useLayoutEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";

import MetisMenu from "react-metismenu";

// import {
//   MainNav,
//   ComponentsNav,
//   FormsNav,
//   WidgetsNav,
//   ChartsNav,
// } from "./NavItems";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Roles } from "../../utils/Roles";
import axios from "axios";
import cookie from "react-cookies";
import { resetStateAction } from "../../reducers/ResetReducer";
import Storage from "../../services/Storage";

const API_URL = process.env.REACT_APP_API_URL;

// class Nav extends Component {
//   state = {};

//   render() {
//     return (
//       <Fragment>
//         <MetisMenu
//           content={MainNav}
//           activeLinkFromLocation
//           className="vertical-nav-menu"
//           iconNamePrefix=""
//           classNameStateIcon="pe-7s-angle-down"
//           onSelected={e => onMenuSelected(e)}
//         />
//         <h5 className="app-sidebar__heading">UI Components</h5>
//         <MetisMenu
//           content={ComponentsNav}
//           activeLinkFromLocation
//           className="vertical-nav-menu"
//           iconNamePrefix=""
//           classNameStateIcon="pe-7s-angle-down"
//         />
//         <h5 className="app-sidebar__heading">Dashboard Widgets</h5>
//         <MetisMenu
//           content={WidgetsNav}
//           activeLinkFromLocation
//           className="vertical-nav-menu"
//           iconNamePrefix=""
//           classNameStateIcon="pe-7s-angle-down"
//         />
//         <h5 className="app-sidebar__heading">Forms</h5>
//         <MetisMenu
//           content={FormsNav}
//           activeLinkFromLocation
//           className="vertical-nav-menu"
//           iconNamePrefix=""
//           classNameStateIcon="pe-7s-angle-down"
//         />
//         <h5 className="app-sidebar__heading">Charts</h5>
//         <MetisMenu
//           content={ChartsNav}
//           activeLinkFromLocation
//           className="vertical-nav-menu"
//           iconNamePrefix=""
//           classNameStateIcon="pe-7s-angle-down"
//         />
//       </Fragment>
//     );
//   }

//   isPathActive(path) {
//     return this.props.location.pathname.startsWith(path);
//   }
// }

const Nav = () => {
  let userData = useSelector((state) => state.UserReducer.userObj);
  const [selected, setSelected] = useState("");
  let history = useHistory();

  const onMenuSelected = (data) => {
    const item = data.currentTarget;
    setSelected(item.name);
  };

  const handleLogout = () => {
    //const { userData } = this.props;
    let sessionid = userData && userData?.sessionid;
    let obj = {
      sessionid,
    };
    axios
      .post(`${API_URL}/api/v2/auth/logout/`, obj)
      .then((res) => {
        if (res.status === 200) {
          cookie.remove("user", { path: "/", domain: "siembramobile.com" });
          cookie.remove("sessionid", {
            path: "/",
            domain: "siembramobile.com",
          });
          cookie.remove("role", { path: "/", domain: "siembramobile.com" });
          Storage.removeTokenCookie();
          Storage.removeSessionId();
          Storage.clearLocalStorage();
          Storage.clearSessionStorage();
          history.push("/login");
        }
      })
      .catch((err) => {
        console.log("err: ", err.response);
      });
  };

  const studentMenu = [
    userData?.is_college_email_verified === true
      ? {
          icon: "customIcon goalIcon",
          label: "Student Progress",
          to: "#/student/student-progress",
        }
      : {
          icon: "customIcon goalIcon",
          label: "Student Progress",
          to: "#/student/dashboard",
        },

    {
      icon: "customIcon bragBookIcon",
      label: "My Brag Book",
      content: [
        {
          label: "My Interest",
          to: "#/student/interest",
        },
        {
          label: "My Academics",
          to: "#/student/portfolio/my-academics",
        },
        {
          label: "My Activities",
          to: "#/student/portfolio/my-activities",
        },
        {
          label: "My Athletics",
          to: "#/student/portfolio/my-athletics",
        },
      ],
    },

    {
      icon: "customIcon messageIcon",
      label: "Message Centre",
      to: "#/student/message",
    },

    ...(userData?.is_college_email_verified === true &&
    userData?.need_reflections === true
      ? [
          {
            icon: "customIcon myReflectionListIcon",
            label: "My Reflections",
            to: "#/student/reflections",
          },
        ]
      : []),

    // {
    //   icon: "customIcon collegeCheckListIcon",
    //   label: "Personal Insights",
    //   to: "#/student/personal-insights",
    // },
    // {
    //   icon: "customIcon bragBookIcon",
    //   label: "My Colleges",
    //   to: "#/student/my-colleges",
    // },
    {
      icon: "customIcon myCalendarIcon",
      label: "My Calendar",
      to: "#/student/calendar",
    },
    // {
    //   icon: "customIcon bragBookIcon",
    //   label: "Student Guide",
    //   externalLink: true,
    //   to: "https://frontend.siembramobile.com/WebUserguide/Student270124/index.html",
    // },
    {
      icon: "customIcon signOutIcon",
      label: (
        <span onClick={handleLogout} className="sign-out">
          Sign Out
        </span>
      ),
    },
    // {
    //   icon: "customIcon netCalculatorIcon",
    //   label: "Net Price Calculator",
    //   // to: "#/",
    // },
  ];

  const counselorMenu = [
    {
      label: "COLLEGE CONNECT",
      content: [
        {
          label: "ALL STUDENTS",
          to: "#/counselor/all-students",
        },
        {
          label: "ON TRACK STUDENTS",
          to: "#/counselor/on-track-student",
        },
        {
          label: "AT RISK STUDENTS",
          to: "#/counselor/at-risk-student",
        },
        {
          label: "OFF TRACK STUDENTS",
          to: "#/counselor/off-track-student",
        },
        {
          label: "WATCHLIST",
          to: "#/counselor/watchlist",
        },
      ],
    },
    {
      label: "MESSAGE",
      content: [
        // {
        //   label: "CREATE A NEW MESSAGE",
        //   //to: "",
        // },
        // {
        //   label: "ALL MESSAGES",
        //   to: "#/counselor/message/all-message",
        // },
        {
          label: "STUDENT MESSAGES",
          to: "#/counselor/message/student-message",
        },
        {
          label: "RECRUITER MESSAGES",
          to: "#/counselor/message/recruiter-message",
        },
        {
          label: "GROUP MESSAGES",
          to: "#/counselor/message/group-message",
        },
        {
          label: "DELETED  MESSAGES",
          to: "#/counselor/message/deleted-message",
        },
        // {
        //   label: "MESSAGE TEMPLATES",
        //   //to: "",
        // },
      ],
    },
    {
      label: "CALENDAR",
      to: "#/counselor/calendar",
    },
    {
      label: "MY STUDENT LIST",
      content: [
        {
          label: "CURRENT STUDENT",
          to: "#/counselor/current-student",
        },
        {
          label: "INVITE STUDENTS",
          to: "#/counselor/invite-student",
        },
        {
          label: "ADD EXISTING STUDENTS ",
          to: "#/counselor/add-existing-student",
        },
        {
          label: "EXPORT FILE",
          to: "#/counselor/export-file",
        },
      ],
    },
    {
      label: "ACCOUNT SETTINGS",
      to:
        userData?.is_super_counselor === true
          ? "#/super_admin/profile"
          : "#/counselor/profile",
    },
    {
      label: "COUNSELOR GUIDE",
      externalLink: true,
      to:
        "https://frontend.siembramobile.com/WebUserguide/Counselor239856/index.html",
    },
    {
      label: (
        <span onClick={handleLogout} className="sign-out">
          SIGN OUT
        </span>
      ),
    },
  ];

  const superCounselorMenu = [
    {
      label: "STUDENT STATISTICS",
      content: [
        {
          label: "DOWNLOAD SUMMARY",
          to: "#/super_admin/student_statistics",
        },
      ],
    },
    {
      label: "COLLEGE CONNECT",
      content: [
        {
          label: "ALL STUDENTS",
          to: "#/super_admin/all-students",
        },
        {
          label: "ON TRACK STUDENTS",
          to: "#/super_admin/on-track-student",
        },
        {
          label: "AT RISK STUDENTS",
          to: "#/super_admin/at-risk-student",
        },
        {
          label: "OFF TRACK STUDENTS",
          to: "#/super_admin/off-track-student",
        },
      ],
    },
    {
      label: "MESSAGE",
      content: [
        // {
        //   label: "CREATE A NEW MESSAGE",
        //   //to: "",
        // },
        // {
        //   label: "ALL MESSAGES",
        //   to: "#/super_admin/message/all-message",
        // },
        {
          label: "STUDENT MESSAGES",
          to: "#/super_admin/message/student-message",
        },
        {
          label: "RECRUITER MESSAGES",
          to: "#/super_admin/message/recruiter-message",
        },
        {
          label: "GROUP MESSAGES",
          to: "#/super_admin/message/group-message",
        },
        {
          label: "DELETED  MESSAGES",
          to: "#/super_admin/message/deleted-message",
        },
        {
          label: "MESSAGE TEMPLATES",
          to: "#/super_admin/message/message-template",
        },
      ],
    },
    {
      label: "CALENDAR",
      to: "#/super_admin/calendar",
    },
    {
      label: "MY STUDENT LIST",
      content: [
        {
          label: "CURRENT STUDENT",
          to: "#/super_admin/current-student",
        },
        {
          label: "INVITE STUDENTS",
          to: "#/super_admin/invite-student",
        },
        {
          label: "ADD EXISTING STUDENTS ",
          to: "#/super_admin/add-existing-student",
        },
        {
          label: "EXPORT FILE",
          to: "#/super_admin/export-file",
        },
      ],
    },
    {
      label: "ACCOUNT SETTINGS",
      to:
        userData?.is_super_counselor === true
          ? "#/super_admin/profile"
          : "#/counselor/profile",
    },
    {
      label: "SUPER ADMIN GUIDE",
      externalLink: true,
      to:
        "https://frontend.siembramobile.com/WebUserguide/SuperAdmin096534/index.html",
    },
    {
      label: (
        <span onClick={handleLogout} className="sign-out">
          SIGN OUT
        </span>
      ),
      // to:"#/login"
    },
  ];

  const recruiterMenu = [
    {
      label: "STUDENT QUERY",
      content: [
        {
          label: "H/S STUDENT QUERY",
          to: "#/recruiter/search-query",
        },
        {
          label: "SAVED QUERIES",
          to: "#/recruiter/school-query",
        },
        {
          label: "C/C STUDENT QUERY",
          to: "#/recruiter/college-query",
        },
      ],
    },
    {
      label: "MESSAGES",
      content: [
        // {
        //   label: "CREATE A NEW MESSAGE",
        //   // to: "",
        // },
        // {
        //   label: "ALL MESSAGES",
        //   // to: "",
        // },
        {
          label: "STUDENT",
          to: "#/recruiter/students",
        },
        {
          label: "INTERNATIONAL STUDENT",
          to: "#/recruiter/international-students",
        },
        {
          label: "COUNSELOR",
          to: "#/recruiter/counselors",
        },
      ],
    },
    {
      label: "MESSAGE TEMPLATE",
      to: "#/recruiter/canned",
    },
    // {
    //   label: "MY STUDENT LIST",
    //   // to: "#",
    // },
    {
      label: "PREMIUM SERVICE",
      to: "#/recruiter/campaign",
    },
    {
      label: "RECRUITER GUIDE",
      externalLink: true,
      to:
        "https://frontend.siembramobile.com/WebUserguide/Recruiter950378/index.html",
    },
    {
      label: (
        <span onClick={handleLogout} className="sign-out">
          SIGN OUT
        </span>
      ),
    },
  ];

  let schoolItMenu = [
    // {
    //         label: "School Setup",
    //         to: "#/school_it/school_setup",
    // },
    {
      label: "Student Statistics",
      to: "#/school_it/student_statistics",
    },
    {
      label: "Message Center",
      to: "#/school_it/message_center",
    },
    {
      label: "Student Messages",
      to: "#/school_it/message-students",
    },
    {
      label: "Group Messages",
      to: "#/school_it/message-groups",
    },
    {
      label: "Export Students",
      to: "#/school_it/csv_export",
    },
    {
      label: "Students",
      to: "#/school_it/students",
    },
    {
      label: "Maintain Groups",
      to: "#/school_it/maintain_group",
    },
    {
      label: "Calendar",
      to: "#/school_it/calendar",
    },
    {
      label: <p>Download Progress</p>,
      to: "#/school_it/dowload_student_status",
    },
    {
      label: (
        <span onClick={handleLogout} className="sign-out">
          Sign Out
        </span>
      ),
    },
    // {
    //   label: "ACCOUNT SETTINGS",
    //   to: "#/counselor/profile",
    // },
    // {
    //   label: <span onClick={handleLogout} className="sign-out">
    //               SIGN OUT
    //          </span>,
    //   // to:"#/login"
    // }
  ];

  if (userData.school_student_progress_enable === true) {
    schoolItMenu = [
      ...schoolItMenu.slice(0, 2),
      { label: "Student Progress", to: "#/school_it/ccplus" },
      ...schoolItMenu.slice(2),
    ];
  }
  //   else if (userData.is_setup === false){
  //     schoolItMenu= [
  //       ...schoolItMenu.slice(0, 1),
  //     {
  //       label: "Student Statistics",
  //       to: "#/school_it/student_statistics",
  //     },
  //     ...schoolItMenu.slice(1)
  //     ]
  // }
  if (userData.is_setup === true) {
    console.log("object");
    schoolItMenu = [
      {
        label: "School Setup",
        to: "#/school_it/school_setup",
      },
      {
        label: "Global Goal",
        to: "#/school_it/global_goal",
      },
      ...schoolItMenu,
    ];
  }

  // if (userData.email === "tim7@sourceauditor.com"){
  //   schoolItMenu= [
  //     ...schoolItMenu.slice(0, 1),
  //     {
  //       label: "SFTP Process",
  //       to: "#/school_it/sftp_process",
  //     },
  //     ...schoolItMenu.slice(1)
  //   ]
  // }

  const adminMenu = [
    {
      label: "Export Students",
      to: "#/admin/export-students",
    },
    {
      label: "Statistics",
      to: "#/admin/statistics",
    },
    {
      label: "Student Progress",
      to: "#/admin/ccplus",
    },
    {
      label: "SFTP Information",
      to: "#/admin/sftp-info",
    },
    {
      label: "Send Invites",
      to: "#/admin/send-invites",
    },
    {
      label: "Schools",
      to: "#/admin/school",
    },
    {
      label: "Students",
      to: "#/admin/students",
    },
    {
      label: "Counselors",
      to: "#/admin/counselors",
    },
    {
      label: "Canned Messages",
      to: "#/admin/canned",
    },
    {
      label: "Update Goals For Schools",
      to: "#/admin/update_schoolgoals",
    },
    {
      label: "Create School IT",
      to: "#/admin/addschool_it",
    },
    {
      label: "Without Counselors",
      to: "#/admin/students_without_counselor",
    },
    {
      label: "Configuration List",
      to: "#/admin/configuration",
    },
    {
      label: "Colleges",
      to: "#/admin/college",
    },
    {
      label: "Recruiters",
      to: "#/admin/recruiters",
    },
    {
      label: "Export Result Report",
      to: "#/admin/exportResultReport",
    },
    {
      label: "Contract Setup",
      to: "#/admin/contractSetup",
    },
    {
      label: (
        <span onClick={handleLogout} className="sign-out">
          Sign Out
        </span>
      ),
    },
    // {
    //   label: "Searches",
    //   to: "#/admin/search-query",
    // },
    // {
    //   label: "ACCOUNT SETTINGS",
    //   to: "#/counselor/profile",
    // },
    // {
    //   label: <span onClick={handleLogout} className="sign-out">
    //               SIGN OUT
    //          </span>,
    //   // to:"#/login"
    // }
  ];

  useEffect(() => {
    if (userData) {
      getMenuList();
    }
  }, [userData]);

  const getMenuList = () => {
    let menuList = [];
    if (userData?.role === Roles.STUDENT_NAME) {
      menuList = studentMenu;
    } else if (
      userData?.role === Roles.COUNSELOR_NAME &&
      userData?.is_super_counselor === true
    ) {
      menuList = superCounselorMenu;
    } else if (userData?.role === Roles.COUNSELOR_NAME) {
      menuList = counselorMenu;
    } else if (userData?.role === Roles.RECRUITER_NAME) {
      menuList = recruiterMenu;
    } else if (userData?.role === Roles.SCHOOL_IT_NAME) {
      menuList = schoolItMenu;
    } else if (userData?.role === Roles.ADMIN_NAME) {
      menuList = adminMenu;
    }
    return menuList;
  };

  return (
    <Fragment>
      <MetisMenu
        content={getMenuList()}
        activeLinkFromLocation
        className="vertical-nav-menu"
        activeLinkTo={selected}
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
        onSelected={(e) => onMenuSelected(e)}
      />
      {/* <h5 className="app-sidebar__heading">UI Components</h5>
      <MetisMenu
        content={ComponentsNav}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />
      <h5 className="app-sidebar__heading">Dashboard Widgets</h5>
      <MetisMenu
        content={WidgetsNav}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />
      <h5 className="app-sidebar__heading">Forms</h5>
      <MetisMenu
        content={FormsNav}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />
      <h5 className="app-sidebar__heading">Charts</h5>
      <MetisMenu
        content={ChartsNav}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      /> */}
    </Fragment>
  );
};
export default Nav;
