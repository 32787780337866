import { request } from "../services/Request";
import { routes } from "../constants/constant.route";
import { urlConstants } from "../utils/urlConstants";

const {API_URL} = urlConstants;

const {
  GET_INTEREST,
  SAVE_INTEREST,
  GET_ACADEMICS,
  GET_ACTIVITIES,
  GET_ATHLETICS,
  SAVE_ACTIVITIES,
  SAVE_ACADEMICS,
  SAVE_ATHLETICS,
  GET_REFLECTION,
  CONFIGURELIST,
} = routes.PORTFOLIO;

export default class PortFolioService {
  static getAcademics(body) {
    return request(
      "GET",
      `${API_URL}${GET_ACADEMICS}`,
      null,
      null,
      null,
      null
    );
  }
  static getActivities(body) {
    return request(
      "GET",
      `${API_URL}${GET_ACTIVITIES}`,
      null,
      null,
      null,
      null
    );
  }
  static getAthletics(body) {
    return request(
      "GET",
      `${API_URL}${GET_ATHLETICS}`,
      null,
      body,
      null,
      null
    );
  }

  static getConfigureList() {
    return request(
      "GET",
      `${API_URL}${CONFIGURELIST}`,
      null,
      null,
      null,
      null
    );
  }

  static getReflection() {
    return request("GET", `${API_URL}${GET_REFLECTION}`, null, null, null, null);
  }

  // static getConfigureList() {
  //   return request("GET", `${API_URL}${CONFIGURELIST}`, null, null, null, null);
  // }
  static saveActivities(body) {
    return request(
      "POST",
      `${API_URL}${SAVE_ACTIVITIES}`,
      null,
      body,
      null,
      null
    );
  }
  static saveAcademics(body) {
    return request(
      "POST",
      `${API_URL}${SAVE_ACADEMICS}`,
      null,
      body,
      null,
      null
    );
  }
  static saveAthletics(body) {
    return request(
      "POST",
      `${API_URL}${SAVE_ATHLETICS}`,
      null,
      body,
      null,
      null
    );
  }
  static getInterest() {
    return request("GET", `${API_URL}${GET_INTEREST}`, null, null, null, null);
  }

  static saveInterest(body) {
    return request(
      "POST",
      `${API_URL}${SAVE_INTEREST}`,
      null,
      body,
      null,
      null
    );
  }
}