import { request } from "../services/Request";
import { routes } from "../constants/constant.route";
import { urlConstants } from "../utils/urlConstants";

const { API_URL } = urlConstants;
const {
  TRANSLATE,
  TRANSLATE_CURR,
} = routes.TRANSLATE;

export default class MessageTemplateService {
  static translateTemplate(body) {
    return request("POST", `${API_URL}${TRANSLATE}`, null, body, null);
  }

  //Curr Translate
  static translateCurrTemplate(body) {
    return request("POST", `${API_URL}${TRANSLATE_CURR}`, null, body, null);
  }
  
  static getCannedMessageForTemplateAfterCreatingNew(body) {
    return request(
      "POST",
      `${API_URL}${routes.cannedMessageForTemplate.cannedMessageForTemplate}`,
      null,
      body,
      null
    );
  }

  static getCannedMessageForTemplateAfterCreatingNewForSuperCounselor(body) {
    return request(
      "POST",
      `${API_URL}${
        routes.cannedMessageTemplateForSuperCounselor
          .cannedMessageTemplateForSuperCounselor
      }`,
      null,
      body,
      null,
      null
    );
  }

  static getCannedMessageForTemplateAfterEditing(body) {
    return request(
      "PUT",
      `${API_URL}${routes.cannedMessageForTemplate.cannedMessageForTemplate}`,
      null,
      body,
      null
    );
  }

  static getCannedMessageForTemplateAfterEditingForSuperCounselor(body) {
    return request(
      "PUT",
      `${API_URL}${
        routes.cannedMessageTemplateForSuperCounselor
          .cannedMessageTemplateForSuperCounselor
      }`,
      null,
      body,
      null,
      null
    );
  }
}
