import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { CommonButton } from "../../components";
import Loading from "../../components/Loading/Loading";
import "../CreateStudentAccount/CreateStudentAccount.scss";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import RegisterService from "../../services/RegisterService";
import { CommonMessages } from "../../constants/CommonMessages";
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

// is_middle_school : false => higherSchoolData
const gradeData = [
  { name: "06", value: "06" },
  { name: "07", value: "07" },
  { name: "08", value: "08" },
  { name: "09", value: "09" },
  { name: "10", value: "10" },
  { name: "11", value: "11" },
  { name: "12", value: "12" },
  { name: "Alumni", value: "Alumni" },
  { name: "No Grade", value: "No Grade" },
];
const higherSchoolData = [
  { name: "09", value: "09" },
  { name: "10", value: "10" },
  { name: "11", value: "11" },
  { name: "12", value: "12" },
  { name: "Alumni", value: "Alumni" },
  { name: "No Grade", value: "No Grade" },
];

const CreateStudentPersonalAccount = (props) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [showPswd, setShowPswd] = useState(false);
  const [showconfirmPswd, setShowconfirmPswd] = useState(false);
  const [states, setStates] = useState([]);
  const [state, setState] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([]);
  const countryRef = useRef();
  const timeoutRef = useRef();

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === "POP") {
        swal({
          text: "Are you sure you want to leave this page?",
          icon: "warning",
          buttons: {
            cancel: true,
            confirm: "Yes",
          },
        }).then((confirmed) => {
          if (confirmed) {
            history.push("/login");
          } else {
            unblock();
          }
        });
        return false;
      }
    });
    return () => {
      unblock();
    };
  }, [history]);

  useEffect(() => {
      setLoading(true);
      RegisterService.getStateDataList()
        .then((res) => {
          setStates(res?.data?.state);
          setLoading(false);
        })
        .catch((error) => {
          console.log("Error fetching states for United States:", error);
          setLoading(false);
        });
  }, []);

  const handleStateChange = (e) => {
    const { value } = e.target;
    setState(value);
    setSelectedState(value);
  };

  const email = localStorage.getItem("userEmail");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      swal("", CommonMessages.passwordMatchError, "error");
    } else {
      let body = {
        email: email,
        first_name: firstName,
        last_name: lastName,
        mobile_number: mobileNo,
        password: password,
        confirm_password: confirmPassword,
        country: 'United States',
        state: selectedState || state,
      };
      RegisterService.personalStudentRegister(body)
        .then((res) => {
          if (res.status === 200) {
            swal({
              text: "Success! Your account has been created.",
              icon: "success",
              buttons: {
                confirm: "OK",
              },
            }).then((confirmed) => {
              if (confirmed) {
                history.push("/login");
              }
            });
          }
        })
        .catch((error) => {
        swal({
            text: error.response.data.error,
            icon: "warning",
            buttons: {
              confirm: "OK",
            },
          })
          setLoading(false);
        });
    }
  };

  return (
    <>
      {loading && <Loading />}
      <div className="studentAccountWrap">
        <div className="studentAccountInner text-center">
          <img
            src={IMG_URL + "white-transfer-logo.png"}
            alt="whiteLogo"
            className="logoImg img-fluid"
          />
          <div className="studentAccountBlockwrap">
            <div className="studentAccountBlockInner">
              <Form onSubmit={handleSubmit} autoComplete="off">
                <div className="boxWrap">
                  <div className="boxwrapInner">
                    <h2 className="accountTitle text-center">
                      Create your student connect account
                    </h2>
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        First Name <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        className="form-control"
                        placeholder="Enter your first name"
                        type="text"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        Last Name <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        className="form-control"
                        placeholder="Enter your last name"
                        type="text"
                        name="lastname"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        Mobile Number <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        className="form-control"
                        placeholder="Enter your mobile number"
                        type="number"
                        name="mobileNo"
                        value={mobileNo}
                        onChange={(e) => setMobileNo(e.target.value)}
                        // onKeyPress={(e) => {
                        //     if (!/[0-9]/.test(e.key)) {
                        //         e.preventDefault();
                        //     }
                        // }}
                      />
                    </FormGroup>
                    <FormGroup className="text-left">
                      <Label for="country">
                        Country <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        className="form-control"
                        placeholder="Enter your country name"
                        type="text"
                        name="country"
                        ref={countryRef}
                        list="listOfCountry"
                        value={'United States'}
                        autoComplete="off"
                        disabled
                      />
                    </FormGroup>
                      <FormGroup className="text-left">
                        <Label for="state">
                          State <span className="requiredField">*</span>
                        </Label>
                          <Input
                            required
                            className="form-control"
                            type="select"
                            name="state"
                            value={selectedState}
                            onChange={handleStateChange}
                          >
                            <option value="" disabled selected>
                              Select your state name
                            </option>
                            {states.map((stat) => (
                              <option key={stat.name} value={stat.name}>
                                {stat.name}
                              </option>
                            ))}
                          </Input>
                      </FormGroup>

                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        Password <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        className="form-control"
                        placeholder="Enter your password"
                        type={showPswd ? "text" : "password"}
                        name="password"
                        minLength="8"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <FontAwesomeIcon
                        icon={showPswd ? faEye : faEyeSlash}
                        onClick={() => setShowPswd(!showPswd)}
                        style={{
                          position: "absolute",
                          right: "13px",
                          top: "41px",
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        Confirm Password{" "}
                        <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        className="form-control"
                        placeholder="Re-enter your password"
                        type={showconfirmPswd ? "text" : "password"}
                        name="confirmPassword"
                        minLength="8"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <FontAwesomeIcon
                        icon={showconfirmPswd ? faEye : faEyeSlash}
                        onClick={() => setShowconfirmPswd(!showconfirmPswd)}
                        style={{
                          position: "absolute",
                          right: "13px",
                          top: "41px",
                        }}
                      />
                    </FormGroup>
                  </div>
                  <div className="btnWrap">
                    <div className="btnWrapper">
                      <div className="btnInner">
                        <CommonButton
                          type="submit"
                          name="Register"
                          btnClass="primaryBtn w-100 stdFormBtn"
                          //   onClick={}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateStudentPersonalAccount;
