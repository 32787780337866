import React, { useState, useRef, useEffect } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { CommonButton } from "../../components";
import "../CreateStudentAccount/CreateStudentAccount.scss";
import { useHistory, Link } from "react-router-dom";
import swal from "sweetalert";
import Loading from "../../components/Loading/Loading";
import RegisterService from "../../services/RegisterService";

const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const CreateStudentOTP = (props) => {
  const history = useHistory();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputsRef = useRef([]);
  const [userEmail, setUserEmail] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedEmail = localStorage.getItem("userEmail");
    if (
      props?.location?.state?.email &&
      props?.location?.state?.email !== userEmail
    ) {
      localStorage.setItem("userEmail", props?.location?.state?.email);
      setUserEmail(props?.location?.state?.email);
    } else if (storedEmail && storedEmail !== userEmail) {
      setUserEmail(storedEmail);
    }

    return () => {
      setOtp(["", "", "", "", "", ""]);
    };
  }, [props?.location?.state?.email, userEmail]);

  const handleChange = (e, index) => {
    const value = e.target.value.replace(/\D/, "");
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 5 && inputsRef.current[index + 1]) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const enteredOtp = otp.join("");

    if (enteredOtp.length !== 6 || isNaN(enteredOtp)) {
      swal({
        text: "Please enter a valid 6-digit OTP",
        icon: "warning",
        buttons: {
          confirm: true,
        },
        closeOnClickOutside: false,
      });
    } else {
      let body = {
        otp: enteredOtp,
        email: userEmail,
      };
      RegisterService.personalOTPVerify(body)
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            history.push({
              pathname: "/create-student-personal-account",
              state: { email: props?.location?.state?.email },
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          swal({
            text: error.response.data.error,
            icon: "warning",
            buttons: {
              confirm: "OK",
            },
          }).then((confirmed) => {
            if (confirmed) {
              setOtp(["", "", "", "", "", ""]);
            }
          });
        });
    }
  };

  const handleResendOTP = (e) => {
    e.preventDefault();
    setLoading(true);
    let body = {
      email: userEmail,
    };
    RegisterService.resendOTPVerify(body)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          swal({
            text: res.data.message,
            icon: "success",
            buttons: {
              confirm: "OK",
            },
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        swal({
          text: error.response.data.error,
          icon: "warning",
          buttons: {
            confirm: "OK",
          },
        });
      });
  };

  return (
    <>
      {loading && <Loading />}
      <div className="studentAccountWrap">
        <div className="studentAccountInner text-center">
          <img
            src={IMG_URL + "white-transfer-logo.png"}
            alt="whiteLogo"
            className="logoImg img-fluid"
          />
          <div className="studentAccountBlockwrap">
            <div className="studentAccountBlockInner">
              <div className="boxWrap">
                <div className="boxwrapInner">
                  <h5 className="text-center">
                    Verification code sent to your email id
                    <br />
                    <b>{userEmail}</b>
                  </h5>
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <FormGroup className="text-left">
                      <div className="d-flex justify-content-between">
                        {[...Array(6)].map((_, index) => (
                          <input
                            key={index}
                            type="text"
                            maxLength={1}
                            className="form-control otp-input"
                            style={{
                              marginRight: "10px",
                              width: "80px",
                              height: "80px",
                              textAlign: "center",
                            }}
                            value={otp[index] || ""}
                            onChange={(e) => handleChange(e, index)}
                            ref={(el) => (inputsRef.current[index] = el)}
                          />
                        ))}
                      </div>
                    </FormGroup>
                    <div className="rememberText">
                      Didn't receive an OTP?
                      <br />
                      <div onClick={handleResendOTP}>
                        <Link to="#" style={{ color: "#049804" }}>
                          Resend OTP
                        </Link>
                      </div>
                    </div>
                    <br />
                    <CommonButton
                      name="Verify"
                      type="submit"
                      btnClass="primaryBtn resendBtn"
                      btnWidth="320px"
                      btnHeight="54px"
                    />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateStudentOTP;
