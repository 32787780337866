export const stateData = [
  { code: "AL", name: "Alabama" },
  { code: "AK", name: "Alaska" },
  { code: "AZ", name: "Arizona" },
  { code: "AR", name: "Arkansas" },
  { code: "CA", name: "California" },
  { code: "CO", name: "Colorado" },
  { code: "CT", name: "Connecticut" },
  { code: "DE", name: "Delaware" },
  { code: "DC", name: "District of Columbia" },
  { code: "FL", name: "Florida" },
  { code: "GA", name: "Georgia" },
  { code: "HI", name: "Hawaii" },
  { code: "ID", name: "Idaho" },
  { code: "IL", name: "Illinois" },
  { code: "IN", name: "Indiana" },
  { code: "IA", name: "Iowa" },
  { code: "KS", name: "Kansas" },
  { code: "KY", name: "Kentucky" },
  { code: "LA", name: "Louisiana" },
  { code: "ME", name: "Maine" },
  { code: "MD", name: "Maryland" },
  { code: "MA", name: "Massachusetts" },
  { code: "MI", name: "Michigan" },
  { code: "MN", name: "Minnesota" },
  { code: "MS", name: "Mississippi" },
  { code: "MO", name: "Missouri" },
  { code: "MT", name: "Montana" },
  { code: "NE", name: "Nebraska" },
  { code: "NV", name: "Nevada" },
  { code: "NH", name: "New Hampshire" },
  { code: "NJ", name: "New Jersey" },
  { code: "NM", name: "New Mexico" },
  { code: "NY", name: "New York" },
  { code: "NC", name: "North Carolina" },
  { code: "ND", name: "North Dakota" },
  { code: "OH", name: "Ohio" },
  { code: "OK", name: "Oklahoma" },
  { code: "OR", name: "Oregon" },
  { code: "PA", name: "Pennsylvania" },
  { code: "RI", name: "Rhode Island" },
  { code: "SC", name: "South Carolina" },
  { code: "SD", name: "South Dakota" },
  { code: "TN", name: "Tennessee" },
  { code: "TX", name: "Texas" },
  { code: "UT", name: "Utah" },
  { code: "VT", name: "Vermont" },
  { code: "VA", name: "Virginia" },
  { code: "WA", name: "Washington" },
  { code: "WV", name: "West Virginia" },
  { code: "WI", name: "Wisconsin" },
  { code: "WY", name: "Wyoming" },
];

export const singleState = [
{ code: "CA", name: "California" },
]

export const gradeData = [
  { value: "09", name: "Freshman" },
  { value: "10", name: "Sophomore" },
  { value: "11", name: "Junior" },
  { value: "12", name: "Senior" },
  { value: "alumni", name: "alumni" },
];

export const gradeData1 = [
  { value: "09", name: "Freshman - 9th" },
  { value: "10", name: "Sophomore - 10th" },
  { value: "11", name: "Junior - 11th" },
  { value: "12", name: "Senior - 12th" },
  { value: "alumni", name: "alumni" },
  { value: "No", name: "No Grade"}
];

export const ucLinkData = [
  { name: "UC Davis", link: "https://www.ucdavis.edu/" },
  { name: "UCI", link: "https://uci.edu/" },
  { name: "UCLA", link: "https://www.ucla.edu/" },
  { name: "UC San Diego", link: "https://www.ucsd.edu/" },
  { name: "UC Santa Cruz", link: "https://www.ucsc.edu/" },
  { name: "UC Santa Barbara", link: "https://www.ucsb.edu/" },
  { name: "UC Riverside", link: "https://www.ucr.edu/" },
  { name: "UC Merced", link: "https://www.ucmerced.edu/" },
];

export const csuLinkData = [
  { name: "CSU Bakersfield", link: "https://www.csub.edu/" },
  { name: "CSU Channel Islands", link: "https://www.csuci.edu/" },
  { name: "CSU Chico", link: "https://www.csuchico.edu/" },
  { name: "CSU Dominguez Hills", link: "https://www.csudh.edu/" },
  { name: "CSU East Bay", link: "https://www.csueastbay.edu/" },
  { name: "CSU Fresno", link: "https://www.fresnostate.edu/" },
  { name: "CSU Fullerton", link: "https://www.fullerton.edu/" },
  { name: "CSU Humboldt", link: "https://www.humboldt.edu/" },
  { name: "CSU Los Angeles", link: "https://www.calstatela.edu/" },
  { name: "CSU Long Beach", link: "https://www.csulb.edu/" },
  { name: "CSU Maritime", link: "https://csum.edu/" },
  { name: "CSU Northridge", link: "https://www.csun.edu/" },
  { name: "CSU Pomona", link: "https://www.cpp.edu/" },
  { name: "CSU Sacramento", link: "https://www.csus.edu/" },
  { name: "CSU San Bernardino", link: "https://www.csusb.edu/" },
  { name: "CSU San Diego", link: "https://sdsu.edu/" },
  { name: "CSU San Francisco", link: "https://www.sfsu.edu/"},
  { name: "CSU San Marcos", link: "https://www.csusm.edu/" },
  { name: "CSU San Jose", link: "https://www.sjsu.edu/" },
  { name: "CSU Cal Poly", link: "https://www.calpoly.edu/" },
  { name: "CSU Sonoma", link: "https://www.sonoma.edu/" },
  { name: "CSU Stanislaus", link: "https://www.csustan.edu/" },
  { name: "CSU Monterery", link: "https://www.csumb.edu/" },
];

export const pcLinkData = [
  { name: "Academy of Art University", link: "https://www.academyart.edu/" },
  {
    name: "Alliant International University",
    link: "https://www.alliant.edu/",
  },
  {
    name: "American Career College",
    link: "https://americancareercollege.edu/",
  },
  { name: "American Jewish University", link: "https://www.aju.edu/" },
  { name: "Antioch University", link: "https://www.antioch.edu/" },
  { name: "ArtCenter College of Design", link: "https://www.artcenter.edu/" },
  { name: "Azusa Pacific", link: "https://apu.edu/" },
  { name: "Bethesda University", link: "https://www.buc.edu/" },
  { name: "Biola University", link: "https://biola.edu/" },
  { name: "Cal Baptist", link: "https://www.calbaptist.edu/" },
  { name: "California Coast University", link: "https://www.calcoast.edu/" },
  { name: "California College of the Arts", link: "https://www.cca.edu/" },
  { name: "California Health Sciences", link: "https://chsu.edu/" },
  {
    name: "California Institute of Integral Studies",
    link: "https://www.ciis.edu/",
  },
  { name: "Cal Tech", link: "https://www.caltech.edu/" },
  { name: "California Institute of the Arts", link: "https://calarts.edu/" },
  { name: "Cal Lutheran", link: "https://www.callutheran.edu/" },
  { name: "Califronia Miramar", link: "https://www.calmu.edu/" },
  { name: "Cal Southern", link: "https://www.calsouthern.edu/" },
  {
    name: "California University of Mgt & Science",
    link: "https://www.calums.edu/",
  },
  { name: "Cambridge College", link: "https://www.cambridgecollege.edu/" },
  { name: "Carrington Health Sciences", link: "https://carrington.edu/" },
  { name: "Chapman University", link: "https://www.chapman.edu/" },
  { name: "Charles Drew Health Sciences", link: "https://www.cdrewu.edu/" },
  { name: "Church Divinity School of the Pacific", link: "https://cdsp.edu/" },
  { name: "Claremont Lincoln", link: "https://www.claremontlincoln.edu/" },
  { name: "Claremont McKenna College", link: "https://www.cmc.edu/" },
  { name: "Concordia University", link: "https://www.cui.edu/" },
  { name: "Dominican", link: "https://www.dominican.edu/" },
  { name: "Epic Bible College", link: "https://epic.edu/" },
  { name: "Fresno Pacific", link: "https://www.fresno.edu/" },
  { name: "Harvey Mudd College", link: "https://www.hmc.edu/" },
  { name: "Hope International", link: "https://www.hiu.edu/" },
  { name: "Humphreys University", link: "https://www.humphreys.edu/" },
  { name: "John Paul Catholic", link: "https://www.jpcatholic.edu/" },
  { name: "Keck Institute", link: "https://www.kgi.edu/" },
  { name: "La Sierra", link: "https://lasierra.edu/" },
  { name: "Lincoln", link: "https://www.lincolnuca.edu/" },
  { name: "Loma Linda", link: "https://www.llu.edu/" },
  { name: "Los Angeles College of Music", link: "https://lacm.edu/" },
  { name: "Loyola Marymount", link: "https://www.lmu.edu/" },
  { name: "Menlo College", link: "https://www.menlo.edu/" },
  {
    name: "Middlebury - International Studies",
    link: "https://www.middlebury.edu/institute",
  },
  { name: "Mills College for Women", link: "https://mills.northeastern.edu/" },
  {
    name: "New School Architecture & Design",
    link: "https://www.newschoolarch.edu/",
  },
  { name: "Notre Dame de Namur", link: "https://www.ndnu.edu/" },
  { name: "Occidental", link: "https://www.oxy.edu/" },
  { name: "Otis College of Art", link: "https://www.otis.edu/" },
  { name: "Pacific School of Religion", link: "https://www.psr.edu/" },
  { name: "Pacific Union", link: "https://www.puc.edu/" },
  { name: "Pepperdine", link: "https://pepperdine.edu/" },
  { name: "Pitzer College", link: "https://www.pitzer.edu/" },
  { name: "Point Loma", link: "https://www.pointloma.edu/" },
  { name: "Pamona College", link: "https://www.pomona.edu/" },
  { name: "Providence Christian", link: "https://www.providencecc.edu/" },
  { name: "St. Mary's", link: "https://www.stmarys-ca.edu/" },
  { name: "Santa Clara", link: "https://scu.edu/" },
  { name: "Saybrook", link: "https://saybrook.edu/" },
  { name: "Scripps", link: "https://www.scrippscollege.edu/" },
  { name: "Simpson", link: "https://www.simpsonu.edu/" },
  { name: "Soka", link: "https://www.soka.edu/" },
  { name: "SoCal Architecture", link: "https://www.sciarc.edu/" },
  { name: "Southern States", link: "https://www.ssu.edu/" },
  { name: "Stanford University", link: "https://www.stanford.edu/" },
  {
    name: "Culinary Institute of America",
    link: "https://www.ciachef.edu/cia-california/",
  },
  { name: "Masters", link: "https://masters.edu/" },
  { name: "Thomas Aquinas", link: "https://www.thomasaquinas.edu/" },
  { name: "Touro University", link: "https://www.tu.edu/" },
  { name: "University of Antelope Valley", link: "https://www.uav.edu/" },
  { name: "University of La Verne", link: "https://www.laverne.edu/" },
  {
    name: "University of Massachusetts Global",
    link: "https://www.umassglobal.edu/",
  },
  { name: "University of Redlands", link: "https://www.redlands.edu/" },
  { name: "University of San Diego", link: "https://www.sandiego.edu/" },
  { name: "University of San Francisco", link: "https://www.usfca.edu/" },
  { name: "USC", link: "https://usc.edu/" },
  { name: "UOP", link: "https://www.pacific.edu/" },
  { name: "University of the People", link: "https://www.uopeople.edu/" },
  { name: "University of the West", link: "https://www.uwest.edu/" },
  { name: "University West LA", link: "https://www.uwla.edu/" },
  { name: "Vanguard", link: "https://vanguard.edu/" },
  { name: "Westcliff", link: "https://www.westcliff.edu/" },
  { name: "Western Health Sciences", link: "https://www.westernu.edu/" },
  { name: "Westmont", link: "https://www.westmont.edu/" },
  { name: "Whittier", link: "https://www.whittier.edu/" },
  { name: "William Jessup", link: "https://www.jessup.edu/" },
  { name: "Woodbury", link: "https://woodbury.edu/" },
];

export const gender = [
  {
    name: "Female",
    value: "female",
    send: "Female"
  },
  {
    name: "Male",
    value: "male",
    send: "Male"
  },
  {
    name: "Non-Binary",
    value: "unknown",
    send: "Non-Binary",
  },
];

export const config = [
  {
    name: "High School",
    value: "high school",
  },
  {
    name: "Grade",
    value: "grade",
  },
  {
    name: "State",
    value: "state",
  },
  {
    name: "Athletics",
    value: "athletics",
  },
  {
    name: "Activities",
    value: "activities",
  },
  {
    name: "Overall GPA",
    value: "overall gpa",
  },
  {
    name: "Gender",
    value: "gender",
  },
  {
    name: "Ethnicity",
    value: "ethnicity",
  },
  {
    name: "Course Concentration",
    value: "course concentration",
  },
  {
    name: "Minimum Goal",
    value: "minimum goal",
  },
  {
    name: "Student First Generation",
    value: "student first generation",
  },
  {
    name: "Zip Code",
    value: "zip code",
  },
  {
    name: "Academics",
    value: "academics",
  },
  {
    name: "Interests",
    value: "interests",
  },
  {
    name: "Subject Matter Proficiency",
    value: "subject matter proficiency",
  },
  {
    name: "uc_status",
    value: "uc_status",
  },
];

export const campaignData = [
  {
    banner_id: 3,
    currrent: "2021-12-20T07:49:01.258320",
    end_date: "2018-11-28",
    id: 3,
    name: "Stanford University",
    query_id: "",
    resource_uri: "/api/v2/campaign/3",
    result_id: 4,
    running: false,
    start_date: "2018-11-20",
  },
];