import React from "react";
import { useHistory } from "react-router-dom";
import { CommonButton } from "../../components";
import "./VerifyEmail.scss";
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const VerifyEmail = () => {
  const history = useHistory();
  let id = "s9lp-u886";

  const handleVerifyClick = () => {
    history.push("/login");
    // history.push(`/verify-password/${id}`);
  };
  return (
    <div className="verifyEmailWrap">
      <div className="verifyEmailBoxWrap">
        <div className="verifyEmailBoxArea text-center">
          <img
            src={IMG_URL + "white-transfer-logo.png"}
            alt="whiteLogo"
            className="logoImg"
          />
          <div className="verifyEmailBox">
            <img
              src={IMG_URL + "verify-meesage-icon.png"}
              alt="verifyMessageIcon"
              className="img-fluid verifyMessageIcon"
            />
            <h2 className="verifyTitle">Check your email</h2>
            {/* <p>Your account has been created.<br /> Please check your email to verify your account</p> */}
            <p>
              We have received your password reset request. Please check your
              email to update the password.
            </p>
            <CommonButton
              // name='Verify your Email'
              name="Return to Sign in"
              type="button"
              btnClass="primaryBtn verifyBtn"
              btnWidth="320px"
              btnHeight="54px"
              onClick={handleVerifyClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
