import React, { Suspense, lazy, Fragment } from "react";
import Loading from "../components/Loading/Loading";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";

//school_it
import SchoolSetup from "../components/school_it/SchoolSetup/SchoolSetup";
import GlobalGoal from "../components/school_it/GlobalGoal/GlobalGoal";
import SchoolitProfile from "../components/school_it/Profile/SchoolitProfile";
import StudentStatistics from "../components/school_it/StudentStatistics/StudentStatistics";
import Student from "../components/school_it/Student/Student";
import CSVImport from "../components/school_it/CsvImport/CsvImport";
import DownloadStudentSummary from "../components/school_it/DownloadStudentSummary/DownloadStudentSummary";
import MaintainGroup from "../components/school_it/MaintainGroup/MaintainGroup";
import SchoolITStudentMessage from "../components/school_it/StudentMessage/StudentMessage";
import MessageCenter from "../components/school_it/MessageCenter/MessageCenter";
import GroupMessage from "../components/school_it/GroupMessage/GroupMessage";
import GoalCriteriaOrSetup from "../components/school_it/GoalCriteriaOrSetup/GoalCriteriaOrSetup";
import ViewRequiredCourseSet from "../components/school_it/GoalCriteriaOrSetup/ViewRequiredCourseSet/ViewRequiredCourseSet";
import MapCourseData from "../components/school_it/GoalCriteriaOrSetup/MapCourseData/MapCourseData";
import ViewGoalMap from "../components/school_it/GoalCriteriaOrSetup/ViewGoalMap/ViewGoalMap";
import CreateGoalMap from "../components/school_it/GoalCriteriaOrSetup/CreateGoalMap/CreateGoalMap";
import ManageDataSource from "../components/school_it/GoalCriteriaOrSetup/ManageDataSource/ManageDataSource";
import CreateDataSource from "../components/school_it/GoalCriteriaOrSetup/CreateDataSource/CreateDataSource";
import UploadDataSource from "../components/school_it/GoalCriteriaOrSetup/UploadDataSource/UploadDataSource";
import MapCollege from "../components/school_it/GoalCriteriaOrSetup/MapCollege/MapCollege";
import MapGoal from "../components/school_it/GoalCriteriaOrSetup/MapGoal/MapGoal";
import EditGoalMap from "../components/school_it/GoalCriteriaOrSetup/EditGoalMap/EditGoalMap";

import StudentReflection from "../Pages/StudentReflection/StudentReflection.js";


// recruiter
import Profile from "../components/recruiterprofile/Profile.js";
import Query from "../components/query/Query";
import Message from "../components/message/Message";
import SchoolQuery from "../components/schoolquery/SchoolQuery";
import CollegeQuery from "../components/collegequery/CollegeQuery";
import QueryResultCCCComponent from "../components/queryresult/QueryResultCCC";
import QueryResultComponent from "../components/queryresult/QueryResult";
import MessageTemplate from "../components/messageTemplate/MessageTemplate";
import SavedQuery from "../components/savedquery/SavedQuery";
import DigitalCampaign from "../components/digitalCampaign/DigitalCampaign";

//admin
import Import from "../components/admin/Import/Import";
import CannedMessages from "../components/admin/CannedMessages/CannedMessages";
import SchoolitProfiles from "../components/school_it/Profile/SchoolitProfile"
import Statistics from "../components/admin/Statistics/Statistics"
import Invites from "../components/admin/Invites/Invites"
import School from "../components/admin/School/School";
import Students from "../components/admin/Student/Student";
import Counselors from "../components/admin/Counselors/Counselors";
import UpdateGoalsForSchools from "../components/admin/UpdateGoalsForSchools/UpdateGoalsForSchools";
import CreateSchoolIt from "../components/admin/CreateSchoolIt/CreateSchoolIt";
import WithoutCounselor from "../components/admin/WithoutCounselor/WithoutCounselor"
import ConfigurationList from "../components/admin/ConfigurationList/ConfigurationList";
import Colleges from "../components/admin/Colleges/Colleges";
import Recruiters from "../components/admin/Recruiters/Recruiters";
import ContractSetup from "../components/admin/ContractSetup/ContractSetup";
import StudentProgress from "../components/admin/StudentProgress/StudentProgress"
// import Searches from "../components/admin/Searches/Searches";
import CreateCannedMessages from "../components/admin/CannedMessages/CreateCannedMessages";
import CreateGoal from "../components/admin/StudentProgress/CreateGoal/CreateGoal";
import RequiredGoal from "../components/admin/StudentProgress/RequiredGoal/RequiredGoal";
import SftpDetails from "../components/admin/SftpDetails/SftpDetails.js";
import QueryResultInternationalComponent from "../components/queryresult/QueryResultInternational.js";
import ExportResultRecruiter from "../components/admin/ExportResultRecruiter/ExportResultRecruiter.js";


// import Storage from "../services/Storage";

// need to remove
const Dashboard = lazy(() => import("../Pages/Dashboard/Dashboard"));
const Goals = lazy(() => import("../Pages/Goals/Goals"));
const Portfolio = lazy(() => import("../Pages/Portfolio/Portfolio"));
const MyPassion = lazy(() => import("../Pages/MyPassion/MyPassion"));
const StudentPersonalInsights = lazy(() =>
  import("../Pages/StudentPersonalInsights/StudentPersonalInsights")
);
const StudentCalendar = lazy(() =>
  import("../components/StudentCalendar/StudentCalendar")
);
const StudentProfile = lazy(() =>
  import("../components/StudentProfile/StudentProfile")
);
const StudentMessage = lazy(() =>
  import("../components/StudentMessage/StudentMessage")
);
const Websites = lazy(()=> import("../components/websites/CollegeLink"));

const StudentGoal = lazy(() => import("../components/StudentGoal/StudentGoal"));

// Counselor
const Profiles = lazy(() => import("../components/recruiterprofile/Profile"));
const CounselorCollegeConnectStudent = lazy(() => import("../components/counselor/counelorCollegeConnectStudent/CounselorCollegeConnectStudent")); 
const ManageStudent = lazy(() => import("../components/counselor/manageStudent/ManageStudent"));
const CounselorStudentDetail = lazy(() => import("../components/counselor/counselorStudentDetail/CounselorStudentDetail"));
const CounselorGroupMessage = lazy(() => import("../components/counselor/counselorMessageModal/counselorGroupMessage/CounselorGroupMessage"));
const ManageGroupList = lazy(() => import("../components/counselor/counselorMessageModal/manageGroupList/ManageGroupList"));
const CurrentStudent = lazy(() => import("../components/counselor/currentStudent/CurrentStudent"));
const InviteStudent = lazy(() => import("../components/counselor/inviteStudent/InviteStudent"));
const AddExistingStudent = lazy(() => import("../components/counselor/addExistingStudent/AddExistingStudent"));
const ImportFiles = lazy(() => import("../components/counselor/importFiles/ImportFiles"));
const CounselorAllMessage = lazy(() => import("../components/counselor/allMessage/AllMessage"));
const CounselorStudentMessage = lazy(() => import("../components/counselor/counselorStudentMessage/StudentMessage"));
const CounselorCalendar = lazy(() => import("../components/counselor/counselorCalendar/CounselorCalendar"));
const CounselorDeleteMessage = lazy(() => import("../components/counselor/deleteMessage/DeleteCounselorMessage"));
const DownLoadSummary = lazy(() => import("../components/superCounselor/DownloadSummary"));
const CounselorMessageTemplate= lazy(() => import("../components/counselor/counselorMessageTemplate/CounselorMessageTemplate"));

const suspenseRoutesArr = [
  // {
  //   path: "/student/dashboard",
  //   component: "",
  //   header: true,
  //   sidebar: true,
  // },
  {
    path: "/student/dashboard",
    component: Dashboard,
    header: true,
    sidebar: true,
  },
  {
    path: "/student/student-progress",
    component: Goals,
    header: true,
    sidebar: true,
  },
  // {
  //   path: "/student/goal/csu-requirement",
  //   component: Goals,
  //   header: true,
  //   sidebar: true,
  // },
  // {
  //   path: "/student/goal/uc-requirement",
  //   component: Goals,
  //   header: true,
  //   sidebar: true,
  // },
  {
    path: "/student/interest",
    component: MyPassion,
    header: true,
    sidebar: true,
  },
  {
    path: "/student/portfolio/:linkParam?",
    component: Portfolio,
    header: true,
    sidebar: true,
  },
  {
    path: "/student/profile",
    component: StudentProfile,
    header: true,
    sidebar: true,
  },
  {
    path: "/student/my-colleges",
    component: Websites,
    header: true,
    sidebar: true,
  },
  {
    path: "/student/calendar",
    component: StudentCalendar,
    header: true,
    sidebar: true,
  },
  {
    path: "/student/message",
    component: StudentMessage,
    header: true,
    sidebar: true,
  },
  {
    path: "/student/reflections",
    component: StudentReflection,
    header: true,
    sidebar: true,
  },

  {
    path: "/student/personal-insights",
    component: StudentPersonalInsights,
    header: true,
    sidebar: true,
  },


  //Counselor Routes
  {
    // exact: true,
    path: "/counselor/profile",
    component: Profiles,
    //key: "Profile",
    header: true,
    sidebar: true,
  },
  {
    //exact: true,
    path: "/counselor/student-list",
    component: CounselorCollegeConnectStudent,
    //key: "CounselorCollegeConnectStudent",
    header: true,
    sidebar: true,
  },
  {
    exact: true,
    path: "/counselor/all-students",
    component: CounselorCollegeConnectStudent,
    //key: "CounselorCollegeConnectStudent",
    header: true,
    sidebar: true,
  },
  {
    //exact: true,
    path: "/counselor/on-track-student",
    component: CounselorCollegeConnectStudent,
    //key: "CounselorCollegeConnectStudent",
    header: true,
    sidebar: true,
  },
  {
    //exact: true,
    path: "/counselor/at-risk-student",
    component: CounselorCollegeConnectStudent,
    //key: "CounselorCollegeConnectStudent",
    header: true,
    sidebar: true,
  },
  {
    //exact: true,
    path: "/counselor/off-track-student",
    component: CounselorCollegeConnectStudent,
    //key: "CounselorCollegeConnectStudent",
    header: true,
    sidebar: true,
  },
  {
    //exact: true,
    path: "/counselor/watchlist",
    component: CounselorCollegeConnectStudent,
    //key: "CounselorCollegeConnectStudent",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/counselor/manage",
    component: ManageStudent,
    //key: "ManageStudent",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/counselor/student/status",
    component: CounselorStudentDetail,
    //key: "CounselorStudentDetail",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/counselor/student/all-message",
    component: CounselorStudentDetail,
    //key: "CounselorStudentDetail",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/counselor/message/recruiter-message",
    component: CounselorStudentMessage,
    //key: "CounselorStudentDetail",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/counselor/message/group-message",
    component: CounselorStudentMessage,
    //key: "CounselorGroupMessage",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/counselor/message/mange-group-list",
    component: ManageGroupList,
    //key: "ManageGroupList",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/counselor/message/deleted-message",
    component: CounselorDeleteMessage,
    //key: "CounselorDeleteMessage",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/counselor/current-student",
    component: CurrentStudent,
    //key: "CurrentStudent",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/counselor/invite-student",
    component: InviteStudent,
    //key: "InviteStudent",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/counselor/add-existing-student",
    component: AddExistingStudent,
    //key: "AddExistingStudent",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/counselor/export-file",
    component: ImportFiles,
    //key: "ImportFiles",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/counselor/message/all-message",
    component: CounselorAllMessage,
    //key: "CounselorAllMessage",
    header: true,
    sidebar: true,
  },

  {
    //exact: false,
    path: "/counselor/message/student-message",
    component: CounselorStudentMessage,
    //key: "CounselorStudentMessage",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/counselor/calendar",
    component: CounselorCalendar,
    //key: "CounselorCalendar",
    header: true,
    sidebar: true,
  },

  //Super-Counselor Routes
  {
    // exact: true,
    path: "/super_admin/profile",
    component: Profiles,
    //key: "Profile",
    header: true,
    sidebar: true,
  },
  {
    exact: true,
    path: "/super_admin/all-students",
    component: CounselorCollegeConnectStudent,
    //key: "CounselorCollegeConnectStudent",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/super_admin/student/status",
    component: CounselorStudentDetail,
    //key: "CounselorStudentDetail",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/super_admin/message/student-message",
    component: CounselorStudentMessage,
    //key: "CounselorStudentMessage",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/super_admin/message/recruiter-message",
    component: CounselorStudentMessage,
    //key: "CounselorStudentMessage",
    header: true,
    sidebar: true,
  },
 
  {
    //exact: true,
    path: "/super_admin/on-track-student",
    component: CounselorCollegeConnectStudent,
    //key: "CounselorCollegeConnectStudent",
    header: true,
    sidebar: true,
  },
  {
    //exact: true,
    path: "/super_admin/at-risk-student",
    component: CounselorCollegeConnectStudent,
    //key: "CounselorCollegeConnectStudent",
    header: true,
    sidebar: true,
  },
  {
    //exact: true,
    path: "/super_admin/off-track-student",
    component: CounselorCollegeConnectStudent,
    //key: "CounselorCollegeConnectStudent",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/super_admin/current-student",
    component: CurrentStudent,
    //key: "CurrentStudent",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/super_admin/invite-student",
    component: InviteStudent,
    //key: "InviteStudent",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/super_admin/add-existing-student",
    component: AddExistingStudent,
    //key: "AddExistingStudent",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/super_admin/export-file",
    component: ImportFiles,
    //key: "ImportFiles",
    header: true,
    sidebar: true,
  },
  // {
  //   //exact: false,
  //   path: "/super_admin/message/all-message",
  //   component: CounselorAllMessage,
  //   //key: "SuperCounselorAllMessage",
  //   header: true,
  //   sidebar: true,
  // },
  {
    //exact: false,
    path: "/super_admin/calendar",
    component: CounselorCalendar,
    //key: "CounselorCalendar",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/super_admin/student_statistics",
    component: DownLoadSummary,
    //key: "DownLoadSummary",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/super_admin/message/group-message",
    component: CounselorStudentMessage,
    //key: "CounselorStudentMessage",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/super_admin/message/message-template",
    component: CounselorMessageTemplate,
    //key: "CounselorMessageTemplate",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/super_admin/message/mange-group-list",
    component: ManageGroupList,
    //key: "ManageGroupList",
    header: true,
    sidebar: true,
  },
  {
    //exact: false,
    path: "/super_admin/message/deleted-message",
    component: CounselorDeleteMessage,
    //key: "CounselorDeleteMessage",
    header: true,
    sidebar: true,
  },
  
  // recruiter
  {
    exact: true,
    path: "/recruiter/search-query",
    component: Query,
    //key: "CounselorDeleteMessage",
    header: true,
    sidebar: true,
  },

  {
    exact: true,
    path: "/recruiter/school-query",
    component: SchoolQuery,
    //key: "CounselorDeleteMessage",
    header: true,
    sidebar: true,
  },

  {
    exact: true,
    path: "/recruiter/students",
    component: Message,
    key: "Message",
    header: true,
    sidebar: true,
  },
  {
    exact: true,
    path: "/recruiter/international-students",
    component: Message,
    key: "Message",
    header: true,
    sidebar: true,
  },
  {
    exact: true,
    path: "/recruiter/counselors",
    component: Message,
    key: "Message",
    header: true,
    sidebar: true,
  },
  {
    exact: true,
    path: "/recruiter/query-result",
    component: QueryResultComponent,
    key: "QueryResultComponent",
    header: true,
    sidebar: true,
  },
  {
    exact: true,
    path: "/recruiter/query-result-college-query",
    component: QueryResultCCCComponent,
    key: "QueryResultCCCComponent",
    header: true,
    sidebar: true,
  },
  {
    exact: true,
    path: "/recruiter/query-result-international-query",
    component: QueryResultInternationalComponent,
    key: "QueryInternationalComponent",
    header: true,
    sidebar: true,
  },


  {
    exact: true,
    path: "/recruiter/college-query",
    component: CollegeQuery,
    key: "CollegeQuery",
    header: true,
    sidebar: true,
  },
  {
    exact: true,
    path: "/recruiter/saved-query",
    component: SavedQuery,
    key: "SavedQuery",
    header: true,
    sidebar: true,
  },
  {
    exact: true,
    path: "/recruiter/profile",
    component: Profile,
    key: "Profile",
    header: true,
    sidebar: true,
  },
  {
    exact: true,
    path: "/recruiter/campaign",
    component: DigitalCampaign,
    key: "DigitalCampaign",
    header: true,
    sidebar: true,
  },
  {
    exact: true,
    path: "/recruiter/canned",
    component: MessageTemplate,
    key: "MessageTemplate",
    header: true,
    sidebar: true,
  },

  //School It Routes
  {
    exact: false,
    path: `/school_it/school_setup`,
    component: SchoolSetup,
    key: "SchoolSetup",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: `/school_it/global_goal`,
    component: GlobalGoal,
    key: "GlobalGoal",
    header: true,
    sidebar: true,
  },
  // {
  //   exact: false,
  //   path: `/school_it/sftp_process`,
  //   component: SFTPProcess,
  //   key: "SFTPProcess",
  //   header: true,
  //   sidebar: true,
  // },
  {
    exact: false,
    path: `/school_it/profile`,
    component: SchoolitProfile,
    key: "SchoolitProfile",
    header: true,
    sidebar: true,
  },
  {
    exact: true,
    path: "/school_it/student_statistics",
    component: StudentStatistics,
    key: "StudentStatistics",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/school_it/students",
    component: Student,
    key: "Student",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/school_it/csv_export",
    component: CSVImport,
    key: "CSVImport",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/school_it/calendar",
    component: CounselorCalendar,
    key: "CounselorCalendar",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/school_it/dowload_student_status",
    component: DownloadStudentSummary,
    key: "DownloadStudentSummary",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/school_it/maintain_group",
    component: MaintainGroup,
    key: "MaintainGroup",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/school_it/message-students",
    component: SchoolITStudentMessage,
    key: "SchoolITStudentMessage",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/school_it/message_center",
    component: MessageCenter,
    key: "MessageCenter",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/school_it/message-groups",
    component: GroupMessage,
    key: "GroupMessage",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/school_it/ccplus",
    component: GoalCriteriaOrSetup,
    key: "GoalCriteriaOrSetup",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/school_it/course",
    component: ViewRequiredCourseSet,
    key: "ViewRequiredCourseSet",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/school_it/mapcourse",
    component: MapCourseData,
    key: "MapCourseData",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/school_it/goal",
    component: CreateGoalMap,
    // component: ViewGoalMap,
    key: "ViewGoalMap",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/school_it/create_goal",
    component: CreateGoalMap,
    key: "CreateGoalMap",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/school_it/manageDataSource",
    component: ManageDataSource,
    key: "ManageDataSource",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/school_it/createDataSource",
    component: CreateDataSource,
    key: "CreateDataSource",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/school_it/uploadDataSource",
    component: UploadDataSource,
    key: "UploadDataSource",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/school_it/mapcollege",
    component: MapCollege,
    key: "MapCollege",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/school_it/mapgoal",
    component: MapGoal,
    key: "MapGoal",
    header: true,
    sidebar: true,
  },

   //admin routes
   {
    exact: false,
    path: `/admin/profile`,
    component: SchoolitProfiles,
    key: "SchoolitProfiles",
    header: true,
    sidebar: true,
  },
   {
    exact: false,
    path: `/admin/export-students`,
    component: Import,
    key: "Import",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: `/admin/statistics`,
    component: Statistics,
    key: "Statistics",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: `/admin/sftp-info`,
    component: SftpDetails,
    key: "SFTPDetails",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: `/admin/ccplus`,
    component: StudentProgress,
    key: "StudentProgress",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/admin/goal",
    component: CreateGoal,
    key: "CreateGoalMap",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: "/admin/course",
    component: RequiredGoal,
    key: "ViewRequiredCourseSet",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: `/admin/send-invites`,
    component: Invites,
    key: "Invites",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: `/admin/school`,
    component: School,
    key: "School",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: `/admin/students`,
    component: Students,
    key: "Students",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: `/admin/counselors`,
    component: Counselors,
    key: "Counselors",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: `/admin/canned`,
    component: CannedMessages,
    key: "CannedMessages",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: `/admin/canned-mesage`,
    component: CreateCannedMessages,
    key: "Import",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: `/admin/update_schoolgoals`,
    component: UpdateGoalsForSchools,
    key: "UpdateGoalsForSchools",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: `/admin/addschool_it`,
    component: CreateSchoolIt,
    key: "CreateSchoolIt",
    header: true,
    sidebar: true,
  },
  // {
  //   exact: false,
  //   path: `/admin/students_without_counselor`,
  //   component: WithoutCounselor ,
  //   key: "WithoutCounselor",
  //   header: true,
  //   sidebar: true,
  // },
  {
    exact: false,
    path: `/admin/students_without_counselor`,
    component:  Students,
    key: "WithoutCounselor",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: `/admin/configuration`,
    component: ConfigurationList,
    key: "ConfigurationList",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: `/admin/college`,
    component: Colleges,
    key: "Colleges",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: `/admin/recruiters`,
    component: Recruiters,
    key: "Recruiters",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: `/admin/exportResultReport`,
    component: ExportResultRecruiter,
    key: "ExportResultRecruiter",
    header: true,
    sidebar: true,
  },
  {
    exact: false,
    path: `/admin/contractSetup`,
    component: ContractSetup,
    key: "ContractSetup",
    header: true,
    sidebar: true,
  },
  // {
  //   exact: false,
  //   path: `/admin/search-query`,
  //   component: Searches,
  //   key: "Searches",
  //   header: true,
  //   sidebar: true,
  // },
];

const PrivateRoutes = () => {
  return (
    <Fragment>
      <>
        {suspenseRoutesArr.map((route, i) => (
          <Suspense fallback={<Loading />} key={i}>
            <PrivateRoute
              path={route.path}
              component={route.component}
              // header={route.header}
              // sidebar={route.sidebar}
            />
          </Suspense>
        ))}
      </>
    </Fragment>
  );
};

export default PrivateRoutes;

// const mapStateToProps = (state) => ({
//   auth: state.Auth.auth,
// });

// const mapDispatchToProps = (dispatch) => ({});

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(PrivateRoutes);